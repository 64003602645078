.deleteModal {
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(22, 19, 61, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        z-index: 5000;
    }
    .close {
        position: absolute;
        top: 2.2rem;
        right: 3.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }
    display: flex;
    flex-direction: column;
    width: 88rem;
    position: relative;
    &__title {
        font-family: "Raleway";
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        button {
            outline: none;
            border: none;
            margin-left: auto;
            font-family: "DM Sans";
            cursor: pointer;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.1rem;
            color: #ffffff;
            padding: 1.7rem;
            background: #000000;
            border-radius: 4px;
        }
    }
    &__title {
        font-family: "Raleway";
        font-weight: 700;
        font-size: 2.8rem;
        line-height: 3.3rem;
        text-align: center;
        color: #1a1a1a;
    }
    &__btn-container {
        display: flex;
        align-items: center;
        gap: 2.2rem;
        button {
            outline: none;
            border: none;
            background: #0b0f10;
            border-radius: 8px;
            border: 1px solid #0b0f10;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Raleway";
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.3rem;
            text-align: center;
            color: #ffffff;
            width: 17.5rem;
            height: 5rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                background-color: white;
                color: #0b0f10;
            }
            &:first-of-type {
                border: 1px solid #ff7373;
                color: #f36767;
                color: #f36767;
                background-color: transparent;
                &:hover {
                    background-color: #f36767;
                    color: #fff;
                }
            }
        }
    }
    main {
        background-color: #f8f8f8;
        border-radius: 0px 0px 24px 24px;
        section {
            margin: 4rem 3.2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 82rem;
            height: 32rem;
            background-color: #fff;
            border-radius: 24px;
            gap: 4rem;
        }
    }
}
