.admin {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    nav {
        padding: 1.2rem 3.275rem;
        background: #5c53ec;
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        img {
            width: 8rem;
        }
    }
    &__profile-pic {
        font-family: "Inter";
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: #5c53ec;
        padding: 1.25rem 1.125rem 1.25rem 1.25rem;
        background: #ffffff;
        border-radius: 50px;
    }
    main {
        display: flex;
        flex: 1;
        overflow-y: auto;
    }
    &__main {
        flex: 1;
        padding: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 8rem;
        overflow-y: auto;
    }
    &__navbar {
        width: 25rem;
        background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
        border-right: 1px solid #d7d7d7;
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        padding: 3.5rem 0;
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            li {
                display: flex;
                align-items: center;
                padding: 0.6rem 2.5rem;
                cursor: pointer;
                gap: 1.5rem;
                transition: all 0.3s ease-in-out;
                border-left: 5px transparent solid;
                &.active {
                    border-left-color: #5c53ec;
                }
            }
        }
    }
    &__title {
        font-family: "Syne";
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 122%;
        color: #111111;
        &-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.9rem;
                padding: 0 3.2rem;
                height: 5rem;
                outline: none;
                border: none;
                border: 1px solid #000;
                background: #000000;
                border-radius: 4px;
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 1.6rem;
                cursor: pointer;
                line-height: 2.1rem;
                color: #ffffff;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background: rgba(0, 0, 0, 0.104);
                    color: black;
                }
            }
        }
    }
    &__analytics {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        &-container {
            display: flex;
            align-items: center;
            gap: 3.2rem;
        }
        &-box {
            flex: 1;
            background: #ffffff;
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            padding: 1.8rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            gap: 1.1rem;
            font-family: "DM Sans";
            font-weight: 700;
            font-size: 5.5rem;
            line-height: 100%;
            letter-spacing: -1px;
            color: #393c51;
            span {
                font-family: "DM Sans";
                font-weight: 700;
                font-size: 2.4rem;
                line-height: 122%;
                color: #a6a6a6;
            }
            menu {
                font-family: "DM Sans";
                font-weight: 700;
                font-size: 1.9rem;
                line-height: 1.6rem;
                color: #25d185;
                position: absolute;
                top: 2.2rem;
                right: 3.3rem;
            }
        }
    }
    &__curriculums {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        &-container {
            display: flex;
            // align-items: center;
            gap: 3rem;
        }
        &-more {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            &-container {
                display: flex;
                // align-items: center;
                flex-wrap: wrap;
                gap: 2.6rem;
            }
            &-add {
                background: #ffffff;
                border: 1px dashed #5c53ec;
                border-radius: 12px;
                width: 30rem;
                height: 38rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4.2rem;
                flex-direction: column;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 2.6rem;
                line-height: 2.4rem;
                text-align: center;
                text-transform: capitalize;
                color: #5c53ec;
                cursor: pointer;
                span {
                    width: 9.6rem;
                    height: 9.6rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 4px dashed #5c53ec;
                    font-size: 4rem;
                }
            }
        }
    }
    &__courses {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        &-container {
            display: flex;
            // align-items: center;
            gap: 3rem;
        }
    }
}
