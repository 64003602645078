.tech-careers {
  &-filter-card-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    margin-bottom: 5px;
    img {
      border-radius: 1rem 1rem 0 0;
      cursor: pointer;
    }
  }
  &-card-favourite {
    position: absolute;
    background-color: white;
    top: 20px;
    right: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 19px;
      height: auto;
    }
  }
  &-card-text {
    cursor: pointer;
    margin-top: auto;
    font-family: "Inter";
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #272727;
    padding: 0 5.5rem;
    padding-bottom: 1.4rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
  .tech-careers-card-text {
    font-size: 16px;
    line-height: 19px;
    padding-top: 12px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 650px) {
  .tech-careers-filter-card-container {
    width: 70% !important;
    margin: 0 auto;
  }
}
