.curriculumOverview {
    display: flex;
    flex-direction: column;
    margin-top: 10rem;
    background-image: url("../../assets/img/co-pattern.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 30px) 17rem;
    &__box {
        display: flex;
        width: 114rem;
        margin: 0 auto;
        // padding: 0 8rem;
        align-items: center;
        gap: 10rem;
        &-col-1 {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 3.7rem;
            background-image: url("../../assets/icons/spark.svg");
            background-repeat: no-repeat;
            background-position: 100% 0;
            background-size: 4rem 4rem;
        }
        &-col-2 {
            width: 46rem;
            animation: up-down 3.5s infinite ease-in-out;
        }
        &-name {
            font-family: "Inter";
            font-weight: 800;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: #727272;
        }
        &-course {
            font-family: "Raleway";
            font-weight: 700;
            font-size: 3.9rem;
            line-height: 4.6rem;
            color: #111111;
        }
        p {
            font-family: "DM Sans";
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 3.4rem;
            color: #000000;
        }
        &-btn-container {
            display: flex;
            gap: 2.9rem;
            align-items: center;

            button {
                width: 21.5rem;
                height: 4.7rem;

                font-family: "DM Sans";
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.1rem;
                color: #ffffff;
                background: #000000;
                border-radius: 4px;
                border: 1px solid #000;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: #000;
                    background: transparent;
                }
            }
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                gap: 0.7rem;
                font-family: "Inter";
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.7rem;
                letter-spacing: 0.01em;
                color: #000000;
            }
        }
    }
}

.curriculumCourses {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 89rem;
    margin: 0 auto;
    margin-top: 15.5rem;
    &__head {
        font-family: "Inter";
        font-weight: 800;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #5a5a5a;
    }
    &__title {
        font-family: "Raleway";
        font-weight: 700;
        font-size: 4.3rem;
        line-height: 5rem;
        color: #150d15;
        margin: 1.2rem 0;
    }
    &__text {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 18px;
        line-height: 2.3rem;
        color: #4f4f4f;
    }
    &__container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4rem;
        margin-top: 5rem;
        justify-content: center;
    }
}

.curriculumAbout {
    display: flex;
    gap: 4.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 109rem;
    margin: 0 auto;
    margin-top: 8.8rem;
    background-image: url("../../assets/img/cr-pattern.png");
    background-repeat: no-repeat;
    background-position: 7rem 4.3rem;
    &__col-1 {
        display: flex;
        flex-direction: column;
        width: 57rem;
    }
    &__head {
        font-family: "Inter";
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-transform: capitalize;
        color: #7e787d;
    }
    &__title {
        font-family: "Raleway";
        font-weight: 700;
        font-size: 4.3rem;
        line-height: 5rem;
        text-transform: capitalize;
        color: #1a1a1a;
        margin-top: 1.9rem;
        margin-bottom: 3.7rem;
    }
    p {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 3.3rem;
        color: #4f4f4f;
    }
    a {
        width: fit-content;
        display: flex;
        margin-top: 5.1rem;
        gap: 8px;
        text-decoration: none;
        font-family: "Raleway";
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-inline: auto;
        letter-spacing: 0.01em;
        color: #000000;
        padding-bottom: 1rem;
        border-bottom: 2px solid #b1b1b1;
    }
}

.curriculumBanner {
    background-color: #6257ff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8rem 0;
    margin-top: 10rem;
    &__text {
        font-family: "Raleway";
        font-weight: 800;
        font-size: 4rem;
        line-height: 3.3rem;
        color: #ffffff;
    }
    p {
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3.3rem;
        color: #ffffff;
        margin-top: 3rem;
        margin-bottom: 5rem;
        text-align: center;
    }
    button {
        width: 19rem;
        height: 5.2rem;
        background: #ffffff;
        font-family: "Raleway";
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 3.3rem;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.6rem;
        transition: all 0.3s ease-in-out;
        border: none;
        outline: none;
        cursor: pointer;
        &:hover {
            color: white;
            background-color: #000;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1300px) {
    .curriculumOverview__box {
        width: 100%;
        padding: 0 7.2rem;
    }
    .curriculumAbout {
        width: 100%;
        padding: 0 7.2rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
    .curriculumCourses {
        width: 100%;
        padding: 0 7.2rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1100px) {
    .curriculumOverview__box-col-2 {
        width: 36rem;
    }
    .curriculumAbout__col-1 {
        width: 100%;
    }
    .curriculumAbout__col-2 {
        width: 80rem;
        img {
            width: 100%;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
    .curriculumAbout__col-2 {
        width: 100rem;
        img {
            width: 100%;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
    .curriculumOverview {
        margin-top: 16rem;
    }
    .curriculumOverview__box-col-2 {
        width: 100%;
    }
    .curriculumOverview {
        background-position: calc(100%) 10rem;
    }
    .curriculumOverview__box {
        flex-direction: column-reverse;
        gap: 48px;
        &-name {
            font-weight: 800;
            font-size: 18px;
            line-height: 22px;
        }
    }
    .curriculumAbout {
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        &__col-2 {
            max-width: 50rem;
            width: 100%;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 700px) {
    .curriculumCourses {
        &__container {
            flex-direction: column;
        }
        .courseEnrollCard {
            width: 100%;
            min-width: none;
            max-width: none;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
    .curriculumCourses {
        margin-top: 32px;
        &__container {
            gap: 24px;
        }
        &__head {
            font-weight: 800;
            font-size: 12px;
            line-height: 15px;
        }
        &__title {
            font-weight: 700;
            font-size: 24px;
            margin: 8px 0;
            line-height: 28px;
        }
        &__text {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #4f4f4f;
        }
    }
    .curriculumAbout {
        &__head {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
        &__title {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
        }
    }
    .curriculumBanner {
        padding: 32px 28px;
        margin-top: 34px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
    .curriculumOverview__box {
        padding: 0 28px;
        &-col-1 {
            gap: 0;
        }
        &-name {
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;
        }
        &-course {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            margin-top: 8px;
            margin-bottom: 16px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            margin-bottom: 24px;
        }
        button {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            /* identical to box height */

            color: #ffffff;
            width: 191px;
            height: 41px;
        }
    }
    .curriculumCourses {
        padding: 0 28px;
    }
    .curriculumAbout {
        padding: 0 28px;
        margin-top: 32px;
        gap: 24px;
        &__col-2 {
            max-width: none;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 450px) {
    .curriculumBanner {
        &__text {
            font-size: 3.4rem;
            text-align: center;
        }
        p {
            width: 80%;
        }
    }
}
