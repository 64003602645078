.App.career-finder {
    display: flex;
    flex-direction: column;
    background: linear-gradient(193.58deg, #fbf2ef 0.84%, #f4f5fd 29.52%, #f4f5fd 68.44%, #fbf2ef 99.17%);
}

.careerFinder {
    flex: 1;
    display: flex;
    align-items: center;
    min-height: 90vh;

    padding: 12rem 8rem 4rem 8rem;
    gap: 7.2rem;

    background-image: url("../../assets/img/sprinkleStar.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 7rem) 0px;

    &__details {
        display: flex;
        flex-direction: column;
        flex: 1;
        &-title {
            font-family: "DM Sans";
            font-weight: 700;
            font-size: 5.5rem;
            line-height: 7.2rem;
            color: #000000;
            position: relative;
            img {
                position: absolute;
                top: -6.7rem;
                left: 5.7rem;
                transform: rotate(38.32deg);
            }
        }
        &-subtitle {
            margin-top: 3.2rem;
            margin-bottom: 4rem;
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 3.3rem;
            line-height: 4.3rem;
            color: #737373;
        }
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
            li {
                display: flex;
                align-items: center;
                gap: 1.6rem;
                font-family: "DM Sans";
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 137.7%;
                color: #111111;
                img {
                    padding: 7px 12px;
                    background: #ffffff;
                    box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
                    border-radius: 8px;
                }
            }
        }
    }
}

.MyLandbot {
    flex: 2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    height: 60rem !important;
    iframe {
        height: 60rem !important;
    }
}

.landbot__container{
    width: 100%;
    flex: 2;
    height: 60rem !important;
    iframe {
        height: 60rem !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1440px) {
    .careerFinder {
        gap: 4.2rem;
        padding-inline: 3.6rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1240px) {
    .careerFinder {
        flex-direction: column;
        align-items: flex-start;
        padding-inline: 5.2rem;
        gap: 3.6rem;
        padding-top: 24rem;
        .landbot__container{
            width: 100%;
            flex: 1;
            height: 60rem !important;
            iframe {
                height: 60rem !important;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
 .careerFinder{
    padding-inline: 2.4rem;
}   
}

// @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
//     .careerFinder {
//         .MyLandbot {
//             height: 60rem !important;
//             iframe {
//                 height: 92rem !important;
//             }
//         }
//     }
// }
