.accordian-container {
  width: 309px;
  left: 146px;
  top: 656px;
  background-color: white;
  box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
  border-radius: 12px;
}

.filter-menu-options .MuiSvgIcon-root {
  width: 22px;
  height: 22px;
  fill: #868686;
}

.accordian-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  padding: 15px;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #000000;
}

.accordian-options-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #000000;
}

.accordian-options-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #000000;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: none !important;
  &:before {
    width: 0px !important;
    background-color: white !important;
  }
}

.MuiAccordionDetails-root {
  padding: 0px !important;
}

.filter-btn {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 12px;
  button {
    background: #5c53ec;
    max-width: 200px;
    border-radius: 50px;
    margin: 0 auto;
    font-family: "Raleway";
    flex: 1;
    width: 100%;
    justify-content: center;
    padding: 1.2rem 2.8rem;
    font-style: normal;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    line-height: 23px;
    color: white;
    transition: all 0.3s ease-in-out;
    border: none;
    &:hover {
      background-color: black;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
  .filter-btn {
    button {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
