@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Syne:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

:root {
  --primary-color: #111;
  --secondary-color: #212529;
  --bg-color: #f8f8f8;
  --purple-color: #5c53ec;
}

body {
  font-size: 2.2rem;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  color: var(--primary-color);
  background: white;
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}

.SnackbarContainer-root {
  z-index: 9999 !important;
  margin-bottom: -4rem;
}

.SnackbarItem-message {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.SnackbarItem-wrappedRoot {
  top: 45px !important;
}

@keyframes purple-ball {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(30px, 44px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes pink-ball {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes up-down {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0, 30px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
  html {
    font-size: 56.25%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 670px) {
  html {
    font-size: 50%;
  }
}

// @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
//   html {
//     font-size: 43.75%;
//   }
// }
// @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 450px) {
//   html {
//     font-size: 37.5%;
//   }
// }

.MuiCollapse-wrapper {
  .MuiSvgIcon-root {
    width: 25px;
    height: 25px;
  }
}

.breadCrumbs {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 5rem;
  margin-left: 7%;
  & ~ .curriculumOverview {
    margin-top: 8rem;
  }
  & ~ .courseDetails {
    margin-top: -2rem;
  }

  div {
    font-size: 19px;
    font-weight: 500;
    color: #111;
    display: flex;
    align-items: center;
    gap: 1rem;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
  .breadCrumbs {
    margin-top: 18rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
  .courseDetails {
    padding-top: 4rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
  .breadCrumbs {
    div {
      font-size: 17px;
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 440px) {
  .breadCrumbs {
    div {
      font-size: 14px;
    }
  }
}
