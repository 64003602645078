.courseDetails {
    padding: 10rem 0;
    background-image: url("../../assets/img/sprinkleStar.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 96px) 0rem;

    &__course {
        background-color: white;
        width: 135rem;
        margin: 0 auto;
        background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        border-radius: 24px;
        padding: 6rem;
        display: flex;
        flex-direction: column;
        gap: 6.6rem;

        &-container {
            display: flex;
            align-items: center;
            gap: 6rem;
        }
        &-box {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 25.9rem;
            max-width: 25.9rem;
            min-height: 25.9rem;
            padding: 1.6rem;
            position: relative;
            border: 5px solid #5c53ec;
            border-radius: 24px;
            text-align: center;
            background-size: cover !important;
            background-position: 50% 50% !important;
        }
    }
    &__details {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 3rem;
        &-col-1 {
            display: flex;
            flex-direction: column;
            gap: 3.5rem;
            padding-bottom: 3.7rem;
            border-bottom: 1px solid rgba(215, 215, 215, 0.4);
        }
        &-col-2 {
            display: flex;
            align-items: center;
            gap: 10rem;
        }
        &-tag {
            font-family: "Inter";
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 2.9rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
            text-transform: capitalize;
            color: #000000;
            span {
                font-family: "Inter";
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.2rem;
                letter-spacing: 0.01em;
                text-transform: capitalize;
                color: #7719cc;
                padding: 0.8rem 2rem;
                border: 1px solid #7719cc;
                border-radius: 8.8rem;
            }
        }
        &-title {
            font-family: "Raleway";
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.8rem;
            color: #111111;
        }
        &-btn {
            display: flex;
            align-items: center;
            gap: 3.4rem;
            button {
                outline: none;
                border: none;
                cursor: pointer;
                font-family: "Raleway";
                font-style: normal;
                font-weight: 600;
                font-size: 1.8rem;
                line-height: 2.1rem;
                color: #ffffff;
                padding: 1.5rem 4rem;
                background: #5c53ec;
                border-radius: 50px;
                border: 2px solid #5c53ec;
                transition: all 0.3s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 9px;
                &:hover {
                    color: #5c53ec;
                    background: transparent;
                    border-color: #5c53ec;
                }
            }
            // a:last-of-type {
            //     button {
            //         background: transparent;
            //         border-color: transparent;
            //         color: #5c53ec;
            //         &:hover {
            //             color: #5c53ec;
            //             background: transparent;
            //             border-color: #5c53ec;
            //         }
            //     }
            // }
        }
    }
    &__descrip {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        div {
            font-family: "Raleway";
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.8rem;
            color: #111111;
            display: flex;
            align-items: center;
            gap: 1.2rem;
            svg {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
        p {
            font-family: "DM Sans";
            font-weight: 400;
            font-size: 2rem;
            line-height: 4rem;
            color: #4f4f4f;
            padding-right: 9rem;
        }
    }
    &__related {
        display: flex;
        flex-direction: column;
        width: 135rem;
        margin: 10rem auto 0 auto;
        gap: 5rem;
        &-title {
            font-family: "Raleway";
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 3.8rem;
            color: #111111;
        }
        &-container {
            display: flex;
            gap: 4.8rem;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1500px) {
    .courseDetails {
        &__course {
            width: calc(100% - 14.4rem);
            margin: 0 7.2rem;
        }
        &__related {
            width: 100%;
            padding: 0 7.2rem;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1230px) {
    .courseDetails__details-col-2 {
        gap: 5rem;
    }
    .courseDetails__descrip p {
        padding: 0;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
    .courseDetails__course-container {
        display: flex;
        flex-direction: column;
    }
    .courseDetails__course-box {
        min-width: auto;
        max-width: 350px;
        width: 100%;
        height: 320px;
    }
    .courseDetails__details-btn {
        button {
            width: 250px;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
    .courseDetails {
        padding-top: 10rem;
    }
    .courseDetails__details-col-2 {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 700px) {
    .courseDetails__course {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        background: none;
        box-shadow: none;
    }
    .courseDetails__course-container {
        gap: 24px;
        a {
            width: 100%;
            text-align: center;
        }
        button {
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }
    }
    .courseDetails__details-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }
    .courseDetails__details-tag {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
    .courseDetails__details-tag span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
    }
    .courseDetails__course {
        gap: 35px;
    }
    .courseDetails__descrip {
        gap: 16px;
        div {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
        }
    }
    .courseDetails__related {
        &__container {
            flex-direction: column;
        }
        .courseEnrollCard {
            width: 100%;
            min-width: none;
            max-width: none;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
    .courseDetails__course {
        padding-left: 28px;
        padding-right: 28px;
        padding-bottom: 0;
    }
    .courseDetails__related {
        margin-top: 32px;
    }
}
