.overviewModal {
    display: flex;
    // align-items: center;
    flex-direction: column;
    background-color: #fff;
    background: #ffffff;
    box-shadow: 0px 0px 4.9rem 0.7rem rgba(81, 81, 81, 0.1);
    border-radius: 2.4rem;
    position: relative;
    width: 87.5rem;
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 5000;
        background: rgba(22, 19, 61, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }

    .close {
        position: absolute;
        top: 4.2rem;
        right: 4.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        width: 3.7rem;
        height: 3.7rem;
        &:hover {
            filter: brightness(90%);
        }
    }

    &__header {
        padding: 4rem 4.2rem;
        background: #6257ff;
        border-radius: 12px 12px 0px 0px;
        font-family: "Roboto";
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 4.2rem;
        color: #ffffff;
    }
    &__body {
        padding: 4rem;
        padding-bottom: 5rem;
    }
    &__card {
        background-image: url("../../assets/img/beyonder-bg.png");
        background-size: cover;
        padding: 4rem;
        padding-right: 11rem;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        ul {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            margin-top: 3rem;
            margin-bottom: 5rem;
            list-style: none;
            li {
                font-family: "DM Sans", sans-serif;
                font-weight: 400;
                font-size: 1.7rem;
                line-height: 2.5rem;
                color: #4f4f4f;
                display: flex;
                gap: 2rem;
                img {
                    margin-top: 0.7rem;
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
    &__title {
        font-family: "Raleway";
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        display: flex;
        align-items: center;
        gap: 7px;
        color: #1a1a1a;
        svg {
            width: 2.4rem;
            height: 2.4rem;
        }
    }
    &__tags {
        display: flex;
        row-gap: 2rem;
        column-gap: 3rem;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2.4rem;
        span {
            font-family: "DM Sans";
            font-weight: 400;
            font-size: 1.7rem;
            line-height: 2.5rem;
            color: #7719cc;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 820px) {
    .overviewModal {
        width: 100%;
        margin: 0 28px;
        ul {
            li {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
    .overviewModal__card {
        padding: 2.4rem;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 630px) {
    .overviewModal__body {
        padding: 2.4rem;
        padding-bottom: 3.5rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 440px) {
    .overviewModal__body {
        ul {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }
    .overviewModal__header {
        padding: 2.5rem;
        font-size: 2.8rem;
    }
    .overviewModal {
        .close {
            top: 20px;
            right: 28px;
        }
    }
}
