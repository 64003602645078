.button__group {
    display: flex;
    align-items: center;
    position: absolute;
    top: -25%;
    right: 2%;
    margin-top: 2rem;
    gap: 5px;
    main {
        width: 70px;
        height: 35px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #5c53ec;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:last-of-type {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.profile__communities {
    .button__group {
        top: 100%;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 675px) {
    .button__group {
        top: 100%;
        right: 50%;
        transform: translateX(50%);
    }
}
