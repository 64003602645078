.career_button {
  background: #5c53ec;
  border-radius: 50px;
  font-family: "Raleway";
  padding: 2rem 2.8rem;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 2rem;
  line-height: 2.3rem;
  color: white;
  transition: all 0.3s ease-in-out;
  margin: 47px 0px 0px 0px;
  border: none;
  &:hover {
    background-color: black;
  }
}
