.profile {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    height: 100vh;
    &__info-button-group {
        display: flex;
        align-items: center;
        gap: 2.4rem;
    }

    &__header {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        background: #5c53ec;
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        padding: 1.25rem 32px;
        align-items: center;
        width: 100%;
        position: relative;
        height: 70px;

        ul {
            display: flex;
            align-items: center;
            gap: 3rem;
            list-style: none;
            margin-right: 2%;

            li {
                color: white;
                font-size: 1.7rem;
                font-weight: 400;
                a {
                    color: inherit;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .hamburger {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding-right: 2rem;
            cursor: pointer;
            span {
                width: 25px;
                transition: all 0.3s ease-in-out;
                height: 3px;
                background-color: white;
            }

            &.active {
                span {
                    &:first-of-type {
                        transform: rotate(45deg);
                        margin-bottom: -8px;
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:last-of-type {
                        margin-top: -8px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        img {
            width: 24rem;
            &:first-of-type {
                margin-right: auto;
            }
        }
        div {
            width: 4.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4.3rem;
            font-weight: 700;
            font-size: 2rem;
            line-height: 1.8rem;
            text-transform: uppercase;
            color: #5c53ec;
            background: #ffffff;
            border-radius: 50%;
        }
        .avatar {
            width: 4.3rem;
            height: 4.3rem;
            background-color: white;
            border-radius: 50%;
            &:hover ~ .profile__dropdown {
                height: 40px;
                p {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        &-new {
            display: none;
        }
    }
    &__avatar {
        transition: all 0.3s ease-in-out;
        border: 2px solid transparent;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        position: relative;
        img {
            transition: all 0.3s ease-in-out;
        }
        &:hover {
            // border-color: #000;
            // background: rgba(0, 0, 0, 0.898);
            // img {
            //     opacity: 0.5;
            // }
            // span {
            //     visibility: visible;
            //     opacity: 1;
            //     transform: translate(-50%, -50%);
            // }
        }
        span {
            font-size: 24px;
            font-weight: 600;
            position: absolute;
            opacity: 0;
            text-align: center;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            // visibility: hidden;
            color: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 200%);
        }
    }

    &__dropdown {
        position: absolute;
        transition: all 0.3s ease-in-out;
        width: 110px;
        height: 0px;
        background-color: white;
        border-radius: 7px;
        top: 80%;
        right: 3%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        p {
            opacity: 0;
            display: flex;
            width: 100%;
            visibility: hidden;
            height: 100%;
            align-items: center;
            flex: 1;
            justify-content: center;
            font-size: 1.8rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            color: #5c53ec;
            border-radius: 7px;
            &:hover {
                background-color: #5d53ec44;
            }
        }
        &:hover {
            height: 40px;
            p {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &__container {
        display: flex;
        flex: 1;
        // padding-bottom: 5rem;
        height: calc(100% - 70px);
    }
    &__navbar {
        width: 25rem;
        overflow: hidden;
        background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
        border: 1px solid #d7d7d7;
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        transition: all 0.3s ease-in-out;
        ul {
            margin-top: 3.5rem;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            li {
                padding: 0.8rem 0;
                display: flex;
                align-items: center;
                gap: 1.8rem;
                font-family: "Raleway", sans-serif;
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.1rem;
                color: #000000;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    border-left: 5px solid #5c53ec;
                }
                svg {
                    margin-left: 2.4rem;
                }
            }
            li.active {
                font-weight: 700;
                border-left: 5px solid #5c53ec;
            }
        }
        &.close {
            width: 0px;
        }
    }
    &__box {
        padding: 3.2rem 3.275rem 3.2rem 3.2rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        gap: 8rem;
        width: calc(100% - 25rem);
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        &-container {
            padding: 4rem 4.2rem;
            background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
            box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
            border-radius: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        &-col-1 {
            display: flex;
            align-items: center;
            gap: 3.2rem;
            img {
                border-radius: 50%;
                width: 17rem;
            }
            p {
                margin-top: -2rem;
                color: #3d42de;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &-name {
            font-family: "DM Sans", sans-serif;
            font-weight: 700;
            font-size: 3rem;
            line-height: 3.9rem;
            color: #000000;
        }
        &-email {
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: #737373;
            margin-bottom: 1.45rem;
            margin-top: 8px;
        }
        &-col-2 {
            background: #ffffff;
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            span {
                font-size: 1.8rem;
                color: #3d42de;
                font-weight: 400;
                margin-bottom: 0.8rem;
            }
            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                    font-family: "Syne";
                    font-weight: 600;
                    font-size: 2.8rem;
                    line-height: 122%;
                    font-feature-settings: "liga" off;
                    color: #111111;
                }
                span {
                    font-family: "Raleway";
                    font-weight: 600;
                    font-size: 1.8rem;
                    line-height: 2.1rem;
                    color: #3d42de;
                }
            }
        }
        &-col-3 {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 3rem;
            div {
                font-size: 3.6rem;
                font-family: "Syne";
                font-weight: 500;
            }
            section {
                display: flex;
                align-items: center;
                gap: 2rem;
                span {
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: white;
                    padding: 6px 12px;
                    background-color: #5c53ec;
                    border-radius: 4rem;
                }
            }
        }
        &-progress-box {
            display: flex;
            align-items: center;
            gap: 1.195rem;
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            margin-top: 3rem;
            color: #5c53ec;
            margin-bottom: 1.5rem;
        }
        &-progress {
            background: rgba(119, 111, 255, 0.2);
            border-radius: 1500px;
            width: 41rem;
            height: 12px;
            div {
                background: linear-gradient(0deg, #5c53ec, #5c53ec);
                border-radius: 1500px;
                width: 0%;
                height: 12px;
            }
        }
        button {
            margin-top: 12px;
            outline: none;
            border: none;
            background: transparent;
            font-family: "Raleway";
            cursor: pointer;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #3d42de;
            padding: 12px 22px;
            border: 1px solid #5c53ec;
            transition: all 0.3s ease-in-out;
            &:hover {
                background: #5c53ec;
                color: white;
            }
        }
    }
    &__wishlist {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        &-button {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            div {
                font-family: "Raleway";
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                padding: 1.7rem 30px;
                color: #111111;
                cursor: pointer;
                transition: all 0.3s ease;
                border-radius: 3.8rem;
                &.active {
                    color: #fefbfa;
                    background-color: #5c53ec;
                    font-weight: 700;
                }
            }
            &-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        &-arrow {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            div {
                padding: 0.4rem 2rem 0.1rem 2rem;
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid #5c53ec;
                border-radius: 50px;
                cursor: pointer;
                &:last-of-type {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        &-carousel {
            width: 100%;
            position: relative;
        }
    }

    &__learnlist {
        display: flex;
        flex-direction: column;
        gap: 3.3rem;
        section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            main {
                display: none;
            }
        }
        &-container {
            display: flex;
            align-items: center;
            gap: 3rem;
            cursor: pointer;
        }
        &-add {
            min-width: 30rem;
            height: 42.8rem;
            background: #ffffff;
            border: 1px dashed #5c53ec;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 4.2rem;
            span {
                color: #5c53ec;
                font-size: 3.6rem;
                font-weight: 600;
                padding: 3.2rem 3.8rem;
                line-height: 100%;
                border: 4px dashed #5c53ec;
                border-radius: 50%;
            }
            font-family: "Inter", sans-serif;
            font-weight: 600;
            font-size: 2.6rem;
            line-height: 2.4rem;
            text-align: center;
            text-transform: capitalize;
            color: #5c53ec;
        }
    }
    &__platforms {
        display: flex;
        flex-direction: column;
        gap: 3.3rem;
        &-container {
            background: #ffffff;
            width: min-content;
            border-radius: 24px;
            height: 31rem;
            display: flex;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 0 4.5rem;
                width: 32.5rem;
                padding-bottom: 4rem;
                text-align: center;
                p {
                    font-family: "DM Sans";
                    font-weight: 400;
                    font-size: 1.6rem;
                    line-height: 161.2%;
                    text-align: center;
                    color: #929292;
                    margin-top: 2rem;
                    margin-bottom: 1.4rem;
                }
                button {
                    outline: none;
                    border: none;
                    font-family: "DM Sans";
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 2.1rem;
                    color: #ffffff;
                    padding: 1.3rem 2.7rem;
                    background: #000000;
                    border-radius: 4px;
                    cursor: pointer;
                    border: 1px solid #000000;
                    &.connected {
                        color: black;
                        background-color: white;
                    }
                }
            }
        }
    }
    &__preferences {
        display: flex;
        flex-direction: column;
        gap: 3.3rem;
        &-container {
            display: flex;
            align-items: center;
            gap: 9rem;
        }
        &-col-1 {
            display: flex;
            flex-direction: column;
            padding: 3rem 9rem 3rem 0;
            border-right: 1px solid rgba(215, 215, 215, 0.5);
        }
        &-industry-tags {
            width: 41rem;
            display: flex;
            gap: 1.6rem;
            align-items: center;
            margin-top: 2.3rem;
            gap: 1.6rem;
            flex-wrap: wrap;
            div {
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.8rem;
                color: #ffffff;
                width: fit-content;
                padding: 0.9rem 2.7rem;
                background: #5c53ec;
                border: 1px solid #5c53ec;
                border-radius: 50px;
            }
        }
        &-brands {
            margin-top: 1.4rem;
            display: flex;
            align-items: center;
            gap: 1.575rem;
            flex-wrap: wrap;
            img {
                width: 120px;
            }
        }
        &-title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            button {
                margin-left: 1.6rem;
                outline: none;
                border: none;
                border: 1px solid #000000;
                border-radius: 4px;
                padding: 0.9rem 3.5rem;
                font-family: "Inter", sans-serif;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 1.9rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                text-transform: capitalize;
                background-color: white;
                color: #000000;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                &:hover {
                    background: #000;
                    color: white;
                }
                svg {
                    margin-left: 0.8rem;
                }
            }
        }
    }
    &__communities {
        display: flex;
        flex-direction: column;
        gap: 3.3rem;
        &-title-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                outline: none;
                border: none;
                border: 1px solid #818a9e;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                font-family: "Raleway";
                font-style: normal;
                font-weight: 700;
                font-size: 2rem;
                line-height: 2.3rem;
                text-align: center;
                padding: 1.7rem 5rem;
                color: #3d42de;
                background: transparent;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: #3d42de;
                    color: white;
                }
            }
        }
        &-container {
            display: flex;
            align-items: center;
            gap: 3.5rem;
        }
        &-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
            box-shadow: 0px 0px 22.4302px 7.47674px rgba(215, 215, 215, 0.16);
            border-radius: 12.8173px;
            width: 300px;
            position: relative;
            &-add {
                position: absolute;
                top: 0.5rem;
                cursor: pointer;
                right: 0.5rem;
                width: 3rem;
                height: 3rem;
                line-height: 1;
                font-size: 2rem;
                border-radius: 50%;
                background-color: rgba(72, 72, 72, 0.157);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        a {
            text-decoration: none;
            .profile__communities-background {
                width: 300px;
                height: 300px;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background-position: 50% 50% !important;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 2.8rem;
                // font-family: "DM Sans";
                // font-family: "Inter";
                // font-family: "Syne";
                font-family: "Raleway";
                font-weight: 700;
                // font-weight: 500;
                text-transform: capitalize;
                span {
                    text-align: center;
                    padding: 0.8rem;
                }
            }
        }
        &-name {
            padding: 2.5rem 0 2rem 0;
            text-decoration: none;
            color: #111111;
            background: white;
            font-weight: 600;
            font-size: 19.2259px;
            line-height: 26px;
            width: 100%;
            border-radius: 0 0 13px 13px;
            text-align: center;
            text-transform: capitalize;
        }
    }
    &__certification {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;
        align-items: center;
        justify-content: center;
        background: rgba(22, 19, 61, 0.679);
        border-radius: 24px;
        min-height: 47rem;
        &-title {
            font-family: "Syne";
            font-weight: 700;
            font-size: 4.4rem;
            line-height: 5.3rem;
            text-align: center;
            letter-spacing: 0.01em;
            color: #ffffff;
        }
        p {
            font-family: "DM Sans";
            font-weight: 700;
            font-size: 3.3rem;
            line-height: 5.2rem;
            text-align: center;
            color: #ffffff;
        }
    }

    &__title {
        font-family: "Syne";
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 122%;
        font-feature-settings: "liga" off;
        color: #111111;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.4rem;
        .break {
            flex-basis: 100%;
            height: 0;
        }
        p {
            font-size: 1.6rem;
            font-family: "Inter", sans-serif;
            line-height: 1.2;
            font-weight: 500;
            color: rgb(133, 133, 133);
            width: 90%;
        }
    }
}

div.profile__avatar-container {
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #5c53ec;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 5rem;
    line-height: 3.6rem;
    color: #5c53ec;
    transition: all 0.3s ease-in-out;
    // &:hover {
    //     color: #000;
    //     background-color: #000;
    //     border-color: #000;
    // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1300px) {
    .profile {
        &__navbar {
            display: none;
        }
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
    .profile__info {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 17px;
            padding: 0;
            background-color: transparent;
            box-shadow: none;
        }
        &-col-1 {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        &-box {
            align-items: center;
        }
        &-name {
            font-size: 28px;
        }
        &-col-2-title div {
            font-weight: 600;
            font-size: 18px;
            line-height: 122%;
        }
        button {
            width: fit-content;
            align-self: center;
        }
    }
    .profile__avatar {
        img {
            width: 220px;
            height: 220px;
        }
    }
    .profile__learnlist-add {
        display: none;
    }
    .profile__learnlist {
        section {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
            main {
                outline: none;
                border: none;
                border: 1px solid #818a9e;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                font-family: "Raleway";
                font-style: normal;
                font-weight: 700;
                font-size: 2rem;
                line-height: 2.3rem;
                text-align: center;
                padding: 1.7rem 5rem;
                color: #3d42de;
                background: transparent;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: #3d42de;
                    color: white;
                }
            }
        }
    }
    .profile__preferences-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }
    .profile__preferences-col-1 {
        padding-right: 0px;
        border: none;
    }
    .profile__preferences-industry-tags {
        width: 100%;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
    .profile__header {
        display: none;
    }
    .profile__communities-container {
        display: flex;
        flex-direction: column;
    }
    .profile__communities-box {
        width: 100%;
        max-width: 500px;
    }
    .profile {
        margin-top: 8rem;
    }
    .profile__header-new {
        display: block;
    }
    .profile__info-col-3 {
        margin-top: 4rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 675px) {
    .profile__box {
        gap: 14rem;
    }

    .profile__learnlist {
        margin-top: -5rem;

        .learnList {
            width: 90%;
            height: unset;
            &__name {
                height: 30rem;
                span {
                    font-size: 2rem;
                }
            }
            &__course {
                padding: 1rem;
            }
        }
        menu {
            width: 4rem;
            height: 4rem;
        }
        .learnList__course-title {
            font-size: 1.6rem !important;
            line-height: 2.2rem !important;
        }
    }
    .profile__wishlist {
        .profile__wishlist-carousel {
            // width: 310px;
            width: 100%;
            align-self: center;

            .courseEnrollCard {
                width: 90%;
            }

            .courseEnrollCard__head {
                width: 80%;
                height: unset;
            }
            .courseEnrollCard__favorite {
                width: 3.6rem;
                height: 3.6rem;
                svg {
                    width: 1.7rem;
                    height: 1.7rem;
                }
            }
            .courseEnrollCard__body span {
                font-size: 1.6rem !important;
                line-height: 2.2rem !important;
                // padding-inline: 1rem !important;
            }

            .courseEnrollCard__body div {
                transform: scale(0.8);
            }

            .courseEnrollCard__body {
                padding: 1rem;
            }

            .tech-careers-filter-card-container {
                width: 90% !important;
                .tech-careers-card-text {
                    font-size: 1.6rem !important;
                    line-height: 2.2rem !important;
                    padding-inline: 1rem !important;
                }
                .tech-careers-card-favourite {
                    width: 3.6rem;
                    height: 3.6rem;
                    svg {
                        width: 1.7rem;
                        height: 1.7rem;
                    }
                }
                // max-width: 55rem;
            }
        }
        .profile__learnlist-container {
            align-self: center;
        }
        .profile__preferences-brands {
            gap: 8.5px;
            img {
                width: 72px;
            }
        }
        .profile__preferences-industry-tags div {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }
        .profile__communities-box img {
            height: 250px;
        }
        .profile__certification-title {
            font-weight: 700;
            font-size: 20px;
            line-height: 53px;
        }
        .profile__certification {
            gap: 6px;
            p {
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
    .profile__title {
        p {
            width: 100%;
        }
    }
    .profile__communities-title-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
    .profile__info-col-3 {
        section {
            justify-content: center;
            flex-wrap: wrap;
        }
        section span {
            white-space: nowrap;
        }
    }
    .profile__info-progress {
        width: 100%;
    }
    .profile__info-col-2 {
        width: 100%;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
    .profile__wishlist-carousel {
        // width: 310px;
        align-self: center;

        .courseEnrollCard {
            width: 90%;
        }

        .courseEnrollCard__head {
            width: 96%;
            height: unset;
        }
    }
    .profile__learnlist {
        .learnList {
            width: 90%;
            height: unset;
            &__name {
                height: 25rem;
                span {
                    font-size: 1.8rem;
                }
            }
            &__course {
                padding: 1rem;
            }
        }
        menu {
            width: 4rem;
            height: 4rem;
        }
        .learnList__course-title {
            font-size: 1.4rem !important;
            // line-height: 2.2rem !important;
        }
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 420px) {
    .profile__wishlist-carousel {
        // width: 310px;
        align-self: center;

        .courseEnrollCard {
            width: 90%;
        }

        .courseEnrollCard__body span {
            font-size: 1.4rem !important;
        }

        .courseEnrollCard__body div {
            transform: scale(0.7);
        }
    }
}
