.adminCourse {
    width: 30rem;
    display: flex;
    background: #ffffff;
    border: 1px solid #818a9e;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    position: relative;
    flex-direction: column;
    height: min-content;
    menu {
        width: 3.9rem;
        height: 3.9rem;
        background: #ffffff;
        border: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
        border-radius: 50%;
        position: absolute;
        top: 1.6rem;
        right: 1.6rem;
        cursor: pointer;

        span {
            background: #bdbdbd;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
        }
        ul {
            position: absolute;
            top: 130%;
            right: 0;
            background: #ffffff;
            border: 1px solid #e6e6e6;
            box-shadow: 1px 0px 10px rgba(173, 184, 216, 0.2);
            border-radius: 8px;
            list-style: none;
            flex-direction: column;
            display: flex;
            width: 13.5rem;
            height: 9.8rem;
            padding: 1.8rem 1.5rem;
            gap: 1.3rem;
            li {
                font-family: "Inter";
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.4rem;
                text-transform: capitalize;
                color: #272727;
            }
        }
    }
    &__img {
        padding: 8px;
        img {
            width: 100%;
            min-height: 300px;
            max-height: 320px;
        }
    }
    &__name {
        padding: 1.5rem 1.2rem;
        background-color: black;
        text-align: center;
        width: 100%;
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        border-radius: 0 0 12px 12px;
        font-family: "DM Sans", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        text-transform: capitalize;
        color: white;
    }
}
