.authModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 7.2rem 5rem;
    background: #ffffff;
    box-shadow: 0px 0px 4.9rem 0.7rem rgba(81, 81, 81, 0.1);
    border-radius: 2.4rem;
    position: relative;

    .logo {
        width: 24rem;
        margin-bottom: 4rem;
    }
    .close {
        position: absolute;
        top: 4%;
        right: 5%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 9999;
        background: rgba(22, 19, 61, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }
    &__title {
        font-family: "Raleway", sans-serif;
        font-weight: 700;
        font-size: 3rem;
        line-height: 3.5rem;
        color: var(--primary-color);
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
    &__input {
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 21px;
        padding: 1rem 45px;
        color: var(--primary-color);
        border: 1px solid rgba(118, 120, 128, 0.5);
        border-radius: 4px;
        outline: none;
        width: 39rem;
        &[type="email"] {
            background-image: url("../../assets/icons/mail.svg");
            background-repeat: no-repeat;
            background-position: 16px 50%;
            background-size: 17px 17px;
        }
        &.password {
            background-image: url("../../assets//icons/padlock.svg");
            background-repeat: no-repeat;
            background-position: 16px 50%;
            background-size: 20px 20px;
        }
        &::placeholder {
            color: #4f4f4f;
        }
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        &__col {
            display: flex;
            align-items: center;
            justify-content: space-between;
            label {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-family: "DM Sans", sans-serif;
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: #4f4f4f;
                input {
                    width: 1.125rem;
                    height: 1.125rem;
                    background: #7719cc;
                    border-color: #7719cc;
                }
            }
            a {
                font-family: "DM Sans", sans-serif;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: #4f4f4f;
            }
        }
        button {
            outline: none;
            color: white;
            font-family: "Raleway", sans-serif;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: white;
            padding: 11px 0;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background: #000;
            &:hover {
                background: transparent;
                color: black;
            }
        }
        div {
            position: relative;
            img {
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-45%);
                cursor: pointer;
            }
        }
    }
    &__text {
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #4f4f4f;
        margin-top: 4rem;
        margin-bottom: 2.4rem;
        display: flex;
        align-items: center;
        gap: 1.4rem;
    }
    &__btn-container {
        display: flex;
        align-items: center;
        gap: 2rem;
        button {
            font-family: "DM Sans", sans-serif;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.1rem;
            display: flex;
            align-items: center;
            color: #4f4f4f;
            padding: 0.8rem;
            height: 3.6rem;
            width: 11rem;
            justify-content: center;
            gap: 0.6rem;
            background: transparent;
            box-shadow: 0px 0px 49px rgba(81, 81, 81, 0.1);
            border-radius: 4px;
            border: 1px solid rgba(118, 120, 128, 0.5);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: white;
                background-color: black;
            }
        }
    }
    &__signup {
        margin-top: 4rem;
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.1rem;
        cursor: pointer;
        color: #4f4f4f;
        span {
            font-weight: 500;
            color: #000;
        }
    }
    &__error-message {
        font-size: 1.5rem;
        font-weight: 400;
        color: rgb(243, 83, 83);
        margin-top: -1rem;
        margin-bottom: -1rem;
        text-align: center;
        width: 39rem;
        &.success {
            color: #4bb543;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 700px) {
    .authModal__btn-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
    .authModal {
        width: 100%;
        margin: 0 28px;
        form {
            width: 100%;
            input {
                width: 100%;
            }
        }
    }
}
