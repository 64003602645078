.addNewCourse {
    width: 875px;
    background-color: white;
    position: relative;
    border-radius: 1.2rem;
    &__title {
        border-radius: 1.2rem 1.2rem 0 0;
        background: #6257ff;
        padding: 3.7rem 4rem;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 4.2rem;

        color: #ffffff;
    }
    .close {
        position: absolute;
        top: 3.8rem;
        right: 3.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }

    menu {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        img {
            width: 180px;
            height: 180px;
        }

        label {
            cursor: pointer;
        }
        input {
            opacity: 0;
            display: none;
        }
    }
    &__container {
        padding: 4rem;
        background-color: #eee;

        section {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 4rem;
            background-color: #fff;
            border-radius: 2.4rem;
            form {
                margin-top: 5rem;
                display: flex;
                flex-direction: column;
                gap: 2.4rem;
                width: 100%;
                label {
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;
                    padding: 1.5rem 1.6rem;
                    border: 1px solid rgba(118, 120, 128, 0.5);
                    border-radius: 4px;
                    width: 100%;
                    input {
                        width: 100%;
                        background-color: transparent;
                        outline: none;
                        border: none;
                        font-family: "DM Sans", sans-serif;
                        font-weight: 400;
                        font-size: 1.6rem;
                        line-height: 2.1rem;
                        color: #4f4f4f;
                    }
                }
                div {
                    display: flex;
                    // align-items: center;
                    gap: 1.6rem;
                    padding: 1.5rem 1.6rem;
                    border: 1px solid rgba(118, 120, 128, 0.5);
                    border-radius: 4px;
                    width: 100%;
                    textarea {
                        font-family: "DM Sans", sans-serif;
                        height: 100px;
                        resize: none;
                        font-weight: 400;
                        font-size: 1.6rem;
                        border: none;
                        outline: none;
                        width: 100%;
                        line-height: 2.1rem;
                        color: #4f4f4f;
                    }
                }
                button {
                    background: #000000;
                    width: 39rem;
                    align-self: center;
                    height: 4rem;
                    outline: none;
                    border: none;
                    border: 2px solid transparent;
                    transition: all 0.3s ease-in-out;
                    font-family: "Raleway";
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    color: white;
                    text-align: center;
                    margin: 2.4rem 0;
                }
            }
        }
    }
}
