.home {
  &__find-career {
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 9rem;
    padding-block: 28rem;
    gap: 8rem;
    max-width: 1600px;
    &-col-1 {
      display: flex;
      flex-direction: column;
      width: 52rem;
      align-items: center;
    }
    &-title {
      font-family: "Inter";
      font-weight: 800;
      font-size: 3rem;
      line-height: 3.6rem;
      text-align: center;
      color: #000000;
    }
    &-para {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 2.2rem;
      line-height: 4.3rem;
      text-align: center;
      margin-top: 1.6rem;
      margin-bottom: 10px;
      color: #4f4f4f;
    }
    &-count {
      font-family: "Roboto";
      font-weight: 600;
      font-size: 6.9rem;
      line-height: 8.1rem;
      text-align: center;
      color: #5c53ec;
    }
    &-text {
      font-family: "Roboto Slab";
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 2.5rem;
      text-align: center;
      color: #54595f;
    }
    &-btn {
      font-family: "Raleway";
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      width: 24.9rem;
      padding: 1.7rem 0;
      border: 1px solid var(--primary-color);
      outline: none;
      margin-top: 4.2rem;
      transition: all 0.3s linear;
      cursor: pointer;
      background-color: var(--primary-color);
      color: white;
      svg {
        width: 2rem;
        height: 2rem;
      }
      &:hover {
        background-color: transparent;
        color: var(--primary-color);
      }
    }
    &-col-2 {
      flex: 1;
      text-align: end;
      img {
        height: auto;
        width: 100%;
        transform: rotate(5.5deg);
      }
    }
  }
  &__career-finder {
    display: flex;
    // align-items: center;
    .MyLandbot {
      height: auto !important;
    }
    margin-top: 14rem;
    margin-bottom: 12rem;
    max-width: 160rem;
    margin-inline: auto;
    gap: 7rem;
    &-details {
      width: 45%;
      display: flex;
      flex-direction: column;
      gap: 5rem;
      padding: 6rem 0;
      h2 {
        font-family: "Syne", sans-serif;
        font-weight: 600;
        font-size: 4.3rem;
        line-height: 5.2rem;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding-left: 15%;
        img {
          width: 3.6rem;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        gap: 2.2rem;
        li {
          display: flex;
          align-items: center;
          gap: 2.2rem;
          font-family: "DM Sans", sans-serif;
          font-weight: 400;
          font-size: 2.2rem;
          line-height: 3.8rem;
          color: #111;
        }
        img {
          padding: 1.2rem 1.7rem;
          background: #ffffff;
          box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
          border-radius: 8px;
        }
      }
    }
  }
  &__technology {
    padding: 34rem 12rem;
    background: rgba(247, 247, 247, 0.55);
    position: relative;
    border: 1px solid #d7d7d7;
    svg.svg {
      position: absolute;
      &:nth-of-type(5) {
        top: 12%;
        left: 8%;
      }
      &:nth-of-type(4) {
        top: 12%;
        left: 42%;
      }
      &:nth-of-type(3) {
        top: 12%;
        left: 70%;
      }
      &:nth-of-type(2) {
        top: 70%;
        left: 60%;
      }
      &:nth-of-type(1) {
        top: 70%;
        left: 28%;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      max-width: 160rem;
      margin: 0 auto;
      align-items: center;
      gap: 5rem;
    }
    &-title {
      font-family: "Syne", sans-serif;
      font-weight: 700;
      font-size: 4.3rem;
      line-height: 5.2rem;
      text-align: center;
      color: #1a1a1a;
      display: flex;
      align-items: center;
      gap: 2.4rem;
    }
    &-button {
      display: flex;
      align-items: center;
      font-weight: 600;
      cursor: pointer;
      font-size: 2.7rem;
      line-height: 3.2rem;
      text-align: center;
      color: #111;
      font-family: "Raleway", sans-serif;
      gap: 2rem;
      span {
        font-weight: 200;
        font-size: 3.4rem;
      }
      padding-bottom: 1.6rem;
      border-bottom: 1px solid rgba(26, 26, 26, 0.34);
    }
  }
  &__courses {
    display: flex;
    border-bottom: 1px solid #d7d7d7;
    &-img {
      flex: 1;
      background-size: cover;
      margin-left: auto;
      justify-self: end;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/Curriculum\ with\ Filters\ \(with\ 3\ boxes\ in\ a\ row\)\ 1.png");
    }
    &-container {
      width: 35%;
      padding: 17rem 8rem;
      padding-bottom: 25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        font-family: "Raleway", sans-serif;
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 2.8rem;
        text-align: center;
        color: #ffffff;
        background: #000000;
        border: 1px solid #111111;
        border-radius: 2.2rem;
        padding: 2rem 6rem;
        margin-top: 8rem;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
      p {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 4.3rem;
        text-align: center;
        color: #4f4f4f;
        margin-top: 5.5rem;
        margin-bottom: 7.5rem;
      }
    }
    &-title {
      font-family: "DM Sans", sans-serif;
      font-weight: 400;
      font-size: 3.2rem;
      line-height: 3.8rem;
      text-align: center;
    }
  }
  &__marketplace {
    margin: 0 auto;
    padding: 0 4rem;
    margin-top: 15rem;
    display: flex;
    gap: 10rem;
    align-items: center;
    max-width: 1600px;
    img {
      max-width: 60rem;
    }
    &-container {
      display: flex;
      flex-direction: column;
    }
    &-head {
      font-family: "Inter";
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #5c53ec;
    }
    &-title {
      font-family: "Syne";
      font-weight: 600;
      font-size: 4.3rem;
      line-height: 5.2rem;
      color: #1a1a1a;
      margin-top: 1.8rem;
      margin-bottom: 3.4rem;
    }
    &-para {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 2.2rem;
      line-height: 3.8rem;
      color: var(--primary-color);
    }
    a {
      color: var(--primary-color);
      text-decoration: none;
      font-family: "Raleway", sans-serif;
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.1rem;
      margin-top: 2.5rem;
      padding-bottom: 0.9rem;
      border-bottom: 1px solid rgba(26, 26, 26, 0.34);
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      gap: 5px;
      width: 192px;
      &:hover {
        color: #38d138;
        border-color: #38d138;
        svg {
          fill: #38d138;
          path {
            stroke: #38d138;
          }
        }
      }
    }
  }
  &__jobs {
    display: grid;
    width: 118rem;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 2.4rem;
    column-gap: 8rem;
    padding: 0 9rem;
    margin-top: 5rem;
    margin: 5rem auto 0 auto;
  }
  &__community {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8rem;
    margin-top: 2rem;
    gap: 15rem;
    position: relative;
    div {
      display: flex;
      flex-direction: column;
      max-width: 53rem;
      p {
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 3rem;
        color: #4f4f4f;
      }
    }
    &-head {
      font-family: "Inter", sans-serif;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-transform: uppercase;
      color: #5c53ec;
    }
    &-title {
      font-family: "Syne", sans-serif;
      font-weight: 600;
      font-size: 4.3rem;
      line-height: 5.2rem;
      letter-spacing: -0.004em;
      color: #1a1a1a;
      margin-top: 1.3rem;
      margin-bottom: 2.1rem;
    }
    &-spark {
      position: absolute;
      top: 3.5rem;
      right: 22.5rem;
    }
    button {
      width: 30rem;
      font-family: "Raleway";
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #5c53ec;
      justify-content: center;
      margin-top: 4rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.75rem 0;
      border: 1px solid rgba(129, 138, 158, 1);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      background-color: transparent;
      outline: none;
      &:hover {
        color: white;
        background-color: black;
      }
    }
  }
  &__companies {
    width: 1400px;
    margin: 0 auto;
    padding-top: 3rem;
    border-top: 1px solid #d7d7d7;
    margin-top: 1.5rem;
    img {
      pointer-events: none;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1700px) {
  .home__career-finder {
    width: 92%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1400px) {
  .home__career-finder {
    gap: 5rem;
    h2 {
      padding: 0;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1300px) {
  .home {
    &__companies {
      width: calc(100% - 18rem);
      margin: 5rem 9rem 0 9rem;
      img {
        pointer-events: none;
      }
    }
  }
  .home__career-finder-details {
    width: 40%;
  }
  .home__courses-container {
    width: 40%;
    padding-inline: 4rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  .home {
    &__marketplace {
      padding: 0 4rem;
      margin-top: 15rem;
      display: flex;
      gap: 4rem;
      align-items: center;
      img {
        width: 45rem;
      }
      &-container {
        display: flex;
        min-width: 44rem;
        flex-direction: column;
      }
    }
    &__jobs {
      width: 100%;
    }
    &__technology {
      img {
        transform: scale(0.8);
      }
    }
    &__career-finder {
      flex-direction: column;
      &-details {
        width: 100%;
      }
      .MyLandbot {
        iframe {
          height: 60rem !important;
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  .home__community {
    margin-top: 5rem;
    gap: 6rem;
    img {
      width: 45rem;
    }
    &-spark {
      display: none;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1075px) {
  .home__courses-container {
    width: 50%;
    padding-inline: 4rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
  .home {
    &__find-career {
      padding-block: 8rem;
      gap: 4rem;
      flex-direction: column-reverse;

      &-col-1,
      &-col-2 {
        width: 100%;
        text-align: center;
      }
      &-col-2 img {
        width: 70%;
      }
    }
    &__technology {
      svg.svg {
        transform: scale(0.6);
        position: absolute;
        &:nth-of-type(5) {
          top: 12%;
          left: 8%;
        }
        &:nth-of-type(4) {
          top: 12%;
          left: 34%;
        }
        &:nth-of-type(3) {
          top: 12%;
          left: 60%;
        }
        &:nth-of-type(2) {
          top: 70%;
          left: 45%;
        }
        &:nth-of-type(1) {
          top: 70%;
          left: 18%;
        }
      }
    }
    &__marketplace {
      flex-direction: column;
      align-items: center;
      padding: 0 9rem;
      &-container {
        align-items: center;
        p {
          text-align: center;
        }
        a {
          justify-content: center;
          width: fit-content;
        }
      }
      &-title {
        text-align: center;
      }
      img {
        width: 100%;
        max-width: 70rem;
      }
    }
    &__jobs {
      width: 100%;
      padding: 0 7rem;
      grid-template-columns: 1fr;
      gap: 4rem;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
  .home__community {
    flex-direction: column;
    img {
      width: 100%;
      max-width: 70rem;
    }
    &-box {
      flex-direction: column;
      display: flex;
      align-items: center;
    }
    &-title {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 800px) {
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1075px) {
  .home__courses-container {
    width: 100%;
    padding-inline: 4rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 750px) {
  .home__companies img {
    width: 150px;
  }
  .jumbotron__btn ~ a img {
    width: 200px;
  }
  // .home__career-finder-details {
  //   align-items: center;
  //   ul {
  //     li {
  //       flex-direction: column;
  //       text-align: center;
  //       font-size: 16px;
  //     }
  //   }
  // }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 680px) {
  .home__technology {
    padding-top: 15rem;
    padding-bottom: 40rem;
    &-title {
      flex-direction: column;
      svg:last-of-type {
        display: none;
      }
    }
    svg.svg {
      transform: scale(0.6);
      position: absolute;
      &:nth-of-type(5) {
        top: 60%;
        left: 12%;
      }
      &:nth-of-type(4) {
        top: 72%;
        left: 13%;
        transform: scale(0.6) rotate(-25deg);
      }
      &:nth-of-type(3) {
        top: 78%;
        left: 40%;
      }
      &:nth-of-type(2) {
        top: 70%;
        left: 45%;
        display: none;
      }
      &:nth-of-type(1) {
        top: 65%;
        left: 40%;
        transform: scale(0.6) rotate(25deg);
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
  .home__technology {
    svg.svg {
      transform: scale(0.5);
      &:nth-of-type(4) {
        top: 72%;
        left: 13%;
        transform: scale(0.5) rotate(-25deg);
      }
      &:nth-of-type(3) {
        top: 78%;
        left: 40%;
      }
      &:nth-of-type(2) {
        top: 70%;
        left: 45%;
        display: none;
      }
      &:nth-of-type(1) {
        top: 65%;
        left: 40%;
        transform: scale(0.5) rotate(25deg);
      }
    }
  }
  .home__career-finder {
    padding: 0;
    padding-inline: 2.4rem;
    margin-top: 2rem;
    h2 {
      font-size: 3.2rem;
      text-align: center;
      align-self: center;
    }
    ul {
      li {
        line-height: 1.2;
      }
    }
  }
  .home__courses-container {
    padding-bottom: 5rem;
    padding-top: 7rem;
    p {
      margin-top: 2.5rem;
      font-size: 2rem;
      margin-bottom: 3.5rem;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
  .home__technology {
    padding-bottom: 50rem;
    svg.svg {
      transform: scale(0.5);
      &:nth-of-type(4) {
        top: 75%;
        left: 10%;
        transform: scale(0.5) rotate(-25deg);
      }
      &:nth-of-type(3) {
        top: 83%;
        left: 32%;
      }
      &:nth-of-type(2) {
        top: 70%;
        left: 45%;
        display: none;
      }
      &:nth-of-type(1) {
        top: 65%;
        left: 35%;
        transform: scale(0.5) rotate(25deg);
      }
      &:nth-of-type(5) {
        top: 58%;
        left: 9%;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 450px) {
  .home__companies img {
    width: 115px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 550px) {
  .home {
    &__find-career {
      &-btn {
        width: 100%;
      }
      &-col-2 img {
        width: 100%;
      }
    }
    &__marketplace {
      margin-top: 8rem;
      padding: 0 4rem;
      &-container {
        padding: 0 5rem;
      }
    }
    &__community {
      button {
        width: 100%;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 420px) {
  .home__technology {
    padding-bottom: 36rem;
    svg.svg {
      transform: scale(0.5);
      &:nth-of-type(4) {
        top: 74%;
        left: 4%;
        transform: scale(0.5) rotate(-25deg);
      }
      &:nth-of-type(3) {
        top: 79%;
        left: 25%;
      }
      &:nth-of-type(2) {
        top: 80%;
        left: 45%;
        display: none;
      }
      &:nth-of-type(1) {
        top: 67%;
        left: 29%;
        transform: scale(0.5) rotate(25deg);
      }
      &:nth-of-type(5) {
        top: 62%;
        left: 2%;
      }
    }
  }
  .home__courses-container p {
    font-size: 1.8rem !important;
  }
}
