.journey-container {
  background: url("../../assets/icons/tilt-spark.svg");
  background-repeat: no-repeat;
  background-position: 36.2rem 0%;
  position: relative;
  .sprinkle-star {
    top: 3rem;
    left: 11.3rem;
    transform: rotate(180deg);
    position: absolute;
  }
}

.journey-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #4d40e3;
  margin-top: 114px;
}

.journey-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 16px;
}

.journey-subtitle {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 161.2%;
  text-align: center;
  color: #4f4f4f;
  margin-top: 35px;
}

.journey-goals {
  width: 975px;
  height: 310px;
  left: 313px;
  top: 2693px;
}

.get-started {
  &__container {
    display: flex;
    z-index: 1000;
    background-color: white;
    width: 97.5rem;
    margin: 0 auto;
    border-radius: 24px;
    margin-top: 8rem;
  }
  &__box {
    padding: 5.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.get-started-options {
  margin-top: 17px;
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #4d5ce5;
}

.get-started-options-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 161.2%;
  color: #929292;
  text-align: center;
  margin-top: 21px;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1500px) {
  .journey-container .sprinkle-star {
    left: 5%;
    width: 10rem;
  }
  .journey-container {
    background-position: 95% 0;
    padding: 0 7.2rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1100px) {
  .get-started__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 45px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 800px) {
  .journey-container {
    background-size: 8rem;
    .sprinkle-star {
      width: 8rem;
    }
  }
  .get-started__container {
    gap: 48px;
  }
  .get-started__box {
    padding: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
  .journey-title {
    margin-top: 32px;
  }
  .journey-text {
    font-size: 24px;
    line-height: 31px;
    margin-top: 8px;
  }
  .journey-subtitle {
    margin-top: 16px;
    font-size: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
  .journey-container {
    padding: 0 28px;
    background-size: 6rem;
  }
  .get-started__container {
    margin-top: 24px;
  }
  .journey-subtitle {
    font-size: 14px;
  }
}
