.team-members {
  width: 100%;
}

.future-learning-title {
  left: 500px;
  top: 744px;
  font-family: "Syne";
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 122%;
  text-align: center;
  text-transform: uppercase;
  color: #544dfc;
}

.future-learning-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
}

.MagicBall {
  width: 17.88px;
  height: 21px;
}

.future-learning-description-1 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 36px;
  text-align: center;
  color: #4f4f4f;
  padding: 0 75px;
  margin: 30px auto 30px auto;
  max-width: 1155px;
}

.future-learning-description-2 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 36px;
  text-align: center;
  color: #4f4f4f;
  padding: 0 75px;
  margin: 0px auto 80px auto;
  max-width: 1155px;
}

.founders {
  &__container {
    display: flex;
    gap: 70px;
    padding: 0 75px;
    margin: 0 auto;
    margin-bottom: 55px;
    justify-content: center;
    align-items: center;
    &-col-1 {
      display: flex;
      gap: 50px;
    }
    &-col-2 {
      width: 530px;
      padding: 45px 60px;
      border-radius: 2rem;
      background-image: url("../../assets/img/beyonder-bg.png");
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      p {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 17px;
        line-height: 32px;
        align-items: center;
        color: #4f4f4f;
      }
      a {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.035em;
        color: #000000;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        img {
          margin-left: 14px;
          margin-right: 8px;
        }
        &:hover {
          color: #544dfc;
          svg {
            stroke: #544dfc;
            path {
              stroke: #544dfc;
            }
          }
        }
      }
    }
  }
  &__founder {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      border-radius: 2rem;
    }
    &-name {
      font-family: "Raleway";
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #131b30;
      margin-top: 26px;
      margin-bottom: 16px;
    }
    &-socials {
      display: flex;
      align-items: center;
      gap: 16px;
      a {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #1a1a1a;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.beyonder {
  &__title {
    font-family: "Raleway";
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #1a1a1a;
    svg {
      margin-left: 11px;
      width: 24px;
      text-align: center;
      height: 24px;
    }
  }
}
