.communityModal {
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(22, 19, 61, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        z-index: 5000;
        overflow-y: auto;
        padding: 3rem;
        padding-block: 7rem;
        max-height: 100vh;
    }
    .close {
        position: absolute;
        top: 4.2rem;
        right: 4.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }
    display: flex;
    flex-direction: column;
    width: 1100px;
    position: relative;
    height: 100%;
    &__title {
        background: #6257ff;
        border-radius: 12px 12px 0px 0px;
        padding: 2.4rem 3.2rem 2.3rem 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        font-family: "Roboto";
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 4.2rem;
        color: #ffffff;
        span {
            font-family: "Roboto", sans-serif;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.1rem;
            color: #ffffff;
        }
    }
    main {
        padding: 4.2rem 3.2rem;
        border-radius: 0 0 12px 12px;
        background-color: #f8f8f8;
        section {
            display: flex;
            flex-direction: column;
            gap: 3.3rem;
            padding: 4rem 2.6rem 4rem 2.6rem;
            background-color: white;
            border-radius: 2.4rem;
            .communityModal__section-title {
                font-family: "Raleway";
                font-weight: 700;
                font-size: 2.4rem;
                line-height: 2.8rem;
                text-align: center;
                color: #1a1a1a;
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }
            p {
                font-size: 1.6rem;
                font-family: "Inter", sans-serif;
                line-height: 1.2;
                font-weight: 500;
                color: rgb(133, 133, 133);
                margin-bottom: 1.2rem;
            }
            .communityModal__section-container {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .break {
                    flex-basis: 100%;
                    height: 0;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 1.2rem;
                    list-style: none;
                    margin-bottom: 3rem;
                    flex-direction: row;
                    flex-wrap: wrap;
                    a {
                        text-decoration: none;
                        .profile__communities-background {
                            width: 30rem;
                            height: 30rem;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                        }
                    }
                    li {
                        font-size: 1.7rem;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                            padding: 0.6rem 1.8rem;
                            border: 1px solid #6257ff;
                            color: #6257ff;
                            cursor: pointer;
                        }
                    }
                }
                .newCourseModal__add-container {
                    width: 100%;
                    margin-top: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .communityModal {
        &__container {
            padding-block: 18rem;
            align-items: flex-start;
        }
    }
}

@media only screen and (max-width: 500px) {
    .communityModal {
        &__title {
            font-size: 3rem;
        }
    }
}
