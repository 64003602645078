.tech-careers-container {
  padding: 9.2rem 14.7rem 9.2rem 14.7rem;
  background-image: url("../../assets/img/sprinkleStar.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 96px) 0rem;
  &__title {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 72px;
    left: 147px;
    top: 149px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 72px;
    color: #000000;
  }
  &__title__color {
    color: #5c53ec;
  }
  &__cursor__color {
    color: #000000;
  }
  &__companies {
    width: 1140px;
    margin: 0 auto;
    padding-top: 3rem;
    margin-top: 1.5rem;

    background: #ffffff;
    box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
    border-radius: 24px;
    img {
      pointer-events: none;
      padding: 27px 45px;
    }
  }
}

.tech__subtitile {
  color: #737373;
}

.filter-cards-container {
  margin-top: 83px !important;
}

.tech-careers-details-box {
  padding: 0 14.7rem;
  display: flex;
  margin-top: 9.2rem;
  gap: 2.5rem;
}

.tech-careers__card-box {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  button {
    background: #5c53ec;
    border-radius: 50px;
    align-self: center;
    text-align: center;
    margin: 0 auto;
    width: 17rem;
    height: 5.1rem;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    border: none;
    border: 1px solid #5c53ec;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: white;
      color: #5c53ec;
    }
  }
}

.tech-careers__tag-container {
  margin-bottom: -3rem;
  padding: 2.4rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
  box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
  border-radius: 12px;
  gap: 2rem;
  button {
    outline: none;
    border: none;
    width: auto;
    height: auto;
    margin: 0;
    background: #000000;
    border-radius: 4px;
    padding: 8px 25px;
    color: #f5f5f5;
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
  section {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    flex: 1;
    width: 100%;
    span {
      color: #fefdfd;
      font-family: "Inter";
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      gap: 14px;
      padding: 8px 16px;
      background: #5c53ec;
      border: 1px solid #5c53ec;
      border-radius: 88px;
      svg {
        cursor: pointer;
      }
    }
  }
}

.tech-careers__card-container {
  display: flex;
  gap: 2rem;
  // align-items: center;
  flex-wrap: wrap;
}

.tech-careers__banner {
  display: flex;
  padding: 9.7rem 0;
  margin-top: 10rem;
  background: #13161f;
  position: relative;
  &-box {
    width: 116rem;
    background: #20222e;
    border-radius: 24px;
    margin: 0 auto;
    padding: 6.5rem 7.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12rem;
    z-index: 10;
  }
  .sprinkle-1 {
    position: absolute;
    top: 6rem;
    right: 14rem;
  }
  .sprinkle-2 {
    position: absolute;
    bottom: 11rem;
    left: 14rem;
    transform: rotate(180deg);
  }
  &-col-1 {
    display: flex;
    gap: 2.3rem;
    flex-direction: column;
  }
  &-title {
    font-family: "Syne";
    font-weight: 600;
    font-size: 4.4rem;
    line-height: 5.3rem;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
  &-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 3.3rem;
    line-height: 5.2rem;
    color: #4d5ce5;
  }
  &-col-2 {
    background: #20222e;
    border: 24px;
    button {
      width: 17.2rem;
      height: 5.2rem;
      background: #4d5ce5;
      border-radius: 50px;
      font-family: "Raleway";
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      border: 1px solid #4d5ce5;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: white;
        color: #4d5ce5;
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1600px) {
  .tech-careers__banner {
    .sprinkle-1 {
      right: 1%;
      top: 5%;
    }
    .sprinkle-2 {
      left: 1%;
      bottom: 5%;
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1575px) {
  .tech-careers__card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .tech-careers-filter-card-container {
      width: 100%;
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1500px) {
  .tech-careers__card-container {
    .tech-careers-card-text {
      font-size: 16px;
      line-height: 22px;
      padding-inline: 3rem;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1300px) {
  .tech-careers-details-box {
    padding: 0 7.2rem;
  }
  .tech-careers-container__companies {
    width: calc(100% - 14.4rem);
    margin: 0 7.2rem;
  }
  .tech-careers__banner {
    padding: 9.7rem 7.2rem;
    &-box {
      width: 100%;
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1150px) {
  .tech-careers__card-container {
    display: flex;
    .tech-careers-filter-card-container {
      width: 28rem;
      .tech-careers-card-text {
        font-size: 18px;
        line-height: 24px;
        padding-inline: 3rem;
      }
    }
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1100px) {
  .accordian-container {
    width: 25rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
  .tech-careers-container {
    padding: 8rem 7.2rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 950px) {
  .tech-careers__banner-box {
    gap: 6rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
  .tech-careers-container {
    margin-top: 8rem;
    padding: 10rem 7.2rem;
  }
  .tech-careers__tag-container {
    display: none;
  }
  .tech-careers-details-box {
    flex-direction: column;
    gap: 4rem;
  }
  .accordian-container {
    width: 100%;
  }
  .tech-careers__card-box {
    .tech-careers-filter-card-container {
      width: 100%;
    }
  }
  .tech-careers__card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 890px) {
  .tech-careers__banner {
    padding: 0;
  }
  .tech-careers__banner-box {
    border-radius: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 850px) {
  .tech-careers__card-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 800px) {
  .tech-careers-container__title {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 4.8rem;
    line-height: 6rem;
    gap: 7px;
  }
  .tech__subtitile {
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 8px;
  }
  .tech-careers-container {
    padding-bottom: 5.4rem;
  }
  .career_button {
    margin-top: 16px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 757px) {
  .tech-careers__banner-box {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .tech-careers__banner-col-2 {
    align-self: flex-start;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 650px) {
  .tech-careers__card-box {
    align-items: center;
    .tech-careers-filter-card-container {
      width: 100% !important;
      .tech-careers-card-text {
        font-size: 1.6rem !important;
        line-height: 2.2rem !important;
        padding-inline: 1rem !important;
      }
      .tech-careers-card-favourite{
        width: 4rem;
        height: 4rem;
        svg{
          width: 2rem;
          height: 2rem;
        }
      }
      // max-width: 55rem;
    }
  }
  .tech-careers-container {
    background-position: calc(100% - 5%) 0rem;
    background-size: 25%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
  .tech-careers-details-box {
    padding: 0 28px;
  }
  .tech-careers__banner-box {
    padding: 32px 28px;
    gap: 24px;
  }
  .tech-careers-container__companies {
    width: calc(100% - 56px);
    margin: 0 28px;
    img {
      width: 180px;
    }
  }
  .tech-careers__banner-title {
    font-size: 22px;
    line-height: 32px;
  }
  .tech-careers__banner-col-1 {
    gap: 8px;
  }
  .tech-careers__banner-text {
    font-size: 16px;
    line-height: 21px;
  }
  .tech-careers-details-box {
    margin-top: 32px;
  }

  .tech-careers__banner-col-2 {
    button {
      width: 144px;
      height: 42px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 700;
      line-height: 17.5px;
      gap: 8px;
    }
  }

  .tech-careers-container {
    padding: 10rem 28px 5.4rem 28px;
  }
}
