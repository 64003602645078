/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 10 version
*/

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.5rem 8rem;
  background: transparent;
  margin-bottom: 0rem;
}
.header.transparent {
  margin-bottom: -7.5rem;
}
.header.small {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.65);
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.header__logo {
  margin-right: auto;
  width: 25rem;
  margin-top: 1rem;
}
.header__logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.header__hamburger {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.7rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header__hamburger div {
  min-width: 2.6rem;
  min-height: 0.15rem;
  width: 2.6rem;
  background: #9b51e0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header__hamburger div:last-of-type {
  min-width: 1.3rem;
  min-height: 0.236rem;
  width: 1.3rem;
}
.header__hamburger.active {
  gap: 0;
}
.header__hamburger.active div {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header__hamburger.active div:last-of-type {
  margin-top: -1.35px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  min-width: 2.6rem;
  min-height: 0.15rem;
  width: 2.6rem;
}
.header ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 4rem;
  gap: 4rem;
}
.header ul a {
  color: var(--secondary-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
}
.header ul a:hover {
  color: var(--purple-color);
}
.header ul a[href="/profile"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 0.8rem;
  text-transform: capitalize;
}
.header ul a[href="/profile"] svg {
  width: 2.5rem;
  height: 2.4rem;
}
.header ul a[href="/profile"] svg g {
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}
.header__login-btn {
  width: 13rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 4.3rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: white;
  background: var(--purple-color);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border: none;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
}
.header__login-btn:hover {
  background: white;
  color: var(--purple-color);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1400px) {
  .header__logo {
    width: 13rem;
  }
  .header.transparent {
    margin-bottom: -10rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  .header {
    padding: 1.5rem 4rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1100px) {
  .header ul {
    margin-right: 3rem;
    gap: 2rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
  .header .profile__avatar img {
    width: 4.5rem;
    height: 4.5rem;
  }

  .header .profile__avatar {
    overflow: hidden;
    border: 2px solid #5c53ec;
  }
  .header div.profile__avatar-container {
    width: 5rem;
    height: 5rem;
    font-size: 2rem;
    border: 1px solid #5c53ec;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
  .header {
    margin-top: 0 !important;
    padding: 2rem 4rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 9000;
    background: rgba(255, 255, 255, 0.798);
    -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    gap: 2.4rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .header.active {
    min-height: 100vh;
  }
  .header.active .header__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .header__menu {
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
  .header__menu a {
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 18px;
    border-top: #e9e9e9 solid 1px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #1a1a1a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.8rem;
  }
  .header__menu a:last-of-type {
    border-bottom: #e9e9e9 solid 1px;
  }
  .header__menu button {
    margin-top: 16px;
    outline: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: "Inter";
    color: #fff;
    padding: 12px 45px;
    background: #f35353;
    border-radius: 50px;
  }
  .header__menu button.login {
    background-color: #5c53ec;
  }
  .header__login-btn {
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .header ul {
    display: none;
  }
  .header__hamburger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
  }
  .header__logo {
    margin: 0 auto;
    width: 12rem;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 550px) {
  .header {
    padding: 2rem 3.2rem;
  }
}
