/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 10 version
*/

.footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #f8f8f8;
    padding: 8.4rem 8rem 3rem 8rem;
    gap: 6rem;
    margin-top: 2.5rem;
}
.footer-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 135rem;
}
.footer__gsv {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    img{
        width: 26rem;
    }
}
.footer__socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 2;
    -ms-flex: 2;
    flex: 2;
}
.footer__socials-box {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.footer__socials-box svg {
    cursor: pointer;
}
.footer__socials p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #1a1a1a;
    margin-top: 11rem;
}
.footer__socials p img {
    margin-left: 0.7rem;
}
.footer__company {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2.4rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 40rem;
}
.footer__company p {
    font-family: "Inter";
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #1a1a1a;
    text-align: center;
}
.footer__company img {
    width: 24rem;
}
.footer__company form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 4rem;
}
.footer__company form input {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    text-align: center;
    color: #000;
    outline: none;
    background-color: transparent;
    height: 4.3rem;
    width: 18rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 2.1rem;
    border: 2px solid #000;
    border-radius: 6px 0px 0px 6px;
}
.footer__company form input::-webkit-input-placeholder {
    color: #858b90;
}
.footer__company form input::-moz-placeholder {
    color: #858b90;
}
.footer__company form input:-ms-input-placeholder {
    color: #858b90;
}
.footer__company form input::-ms-input-placeholder {
    color: #858b90;
}
.footer__company form input::placeholder {
    color: #858b90;
}
.footer__company form button {
    margin-left: -3%;
    border: none;
    background: #000;
    border-radius: 0px 6px 6px 0px;
    height: 4.3rem;
    width: 11.4rem;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
}
.footer-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 3rem 0 2rem;
    border-top: 1px solid #d5d5d5;
}
.footer-2 div {
    font-family: "Inter";
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: #90979a;
}
.footer-2 ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    gap: 4rem;
}
.footer-2 ul a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px;
    font-family: "Inter";
    font-weight: 400;
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: #1a1a1a;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.footer-2 ul a:hover {
    color: var(--purple-color);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1500px) {
    .footer-1 {
        width: 100%;
    }
    .footer-2 {
        gap: 4rem;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .footer-2 ul {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .footer-2 div {
        white-space: nowrap;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
    .footer-1 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 7rem;
        max-width: 60rem;
    }
    .footer__gsv,
    .footer__socials,
    .footer__company {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
    .footer__socials form {
        margin-top: 6rem;
    }
    .footer__socials form input {
        width: 100%;
    }
    .footer__socials p {
        margin-top: 6rem;
    }
    .footer-2 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .footer-2 ul {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
    .footer__company img {
        max-width: 27rem;
    }
    .footer__socials-box svg {
        width: 18px;
        height: 18px;
    }
    .footer__company form {
        margin-top: 1.5rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 500px) {
    .footer-2 ul {
        flex-direction: column;
    }
}
