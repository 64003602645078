.jobcard {
    padding: 2.4rem;
    flex: 1;
    width: 100%;
    background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
    box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
    border-radius: 30px;
    cursor: pointer;
    img {
        width: 100%;
        height: auto;
        border-radius: 30px;
    }
    div {
        font-family: "DM Sans";
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.1rem;
        color: #111111;
        margin-top: 2.2rem;
        margin-bottom: 0.5rem;
    }
    p {
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: #a0a0a1;
    }

    &:nth-of-type(2),
    &:nth-of-type(4) {
        margin-top: 4rem;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
    .jobcard {
        max-width: 60rem;
        margin: 0 auto;
        &:nth-of-type(2),
        &:nth-of-type(4) {
            margin-top: 0;
        }
    }
}
