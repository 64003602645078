.resetPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;
    div {
        font-family: "Raleway", sans-serif;
        font-weight: 700;
        font-size: 3.9rem;
        line-height: 4.6rem;
        color: #111111;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        margin-top: 48px;
        label {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-family: "Raleway", sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;

            color: #5c53ec;
            section {
                position: relative;
            }
            input {
                font-family: "DM Sans", sans-serif;
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 21px;
                padding: 12px 24px;
                color: var(--primary-color);
                border: 1px solid rgba(118, 120, 128, 0.5);
                border-radius: 4px;
                outline: none;
                width: 39rem;
                &::placeholder {
                    color: #4f4f4f;
                }
            }
            img {
                position: absolute;
                top: 50%;
                right: 18.25px;
                cursor: pointer;
                transform: translateY(-50%);
            }
        }
        button {
            outline: none;
            border: none;
            outline: none;
            color: white;
            font-family: "Raleway", sans-serif;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: white;
            padding: 11px 0;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background: #000;
            border: 2px solid transparent;
            &:hover {
                border-color: #000;
                background: transparent;
                color: black;
            }
        }
        p {
            font-size: 1.5rem;
            font-weight: 400;
            color: rgb(243, 83, 83);
            margin-top: -2rem;
            margin-bottom: -2rem;
            text-align: center;
            width: 39rem;
            &.active {
                color: #198754;
            }
        }
    }
}
