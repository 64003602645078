.newCourseModal {
    &__container {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(22, 19, 61, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        z-index: 5000;
        padding: 3rem;
    }
    .close {
        position: absolute;
        top: 4.2rem;
        right: 4.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }
    display: flex;
    flex-direction: column;
    width: 1100px;
    position: relative;
    &__title {
        font-family: "Raleway";
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        button {
            outline: none;
            border: none;
            margin-left: auto;
            font-family: "DM Sans";
            cursor: pointer;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.1rem;
            color: #ffffff;
            padding: 1.7rem;
            background: #000000;
            border-radius: 4px;
        }
    }
    &__course {
        &-container {
            display: flex;
            margin-top: 2rem;
            gap: 2rem;
        }
        &-card {
            display: flex;
            flex-direction: column;
            width: 31.5rem;
            height: 39rem;
            position: relative;
            &-title {
                height: 31.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 3rem;
                text-align: center;
                background-color: white;
                background: #ffffff;
                border: 1px solid #818a9e;
                border-radius: 10px 10px 0px 0px;
                img {
                    width: 60%;
                }
            }
            &-course {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #000;
                background: #0b0f10;
                border-radius: 0px 0px 10px 9px;
                text-align: center;
                padding: 2.6rem 3rem;
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.1rem;
                text-align: center;
                text-transform: capitalize;
                color: #ffffff;
            }
        }
    }
    &__add-container {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-top: 1rem;
        font-family: "Raleway";
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
        color: #1a1a1a;
        form {
            display: flex;
            width: 100%;
            flex: 1;
            align-items: center;
            input {
                border: 1px solid rgba(118, 120, 128, 0.5);
                border-radius: 4px 0 4px 0;
                padding: 1.6rem 5.2rem;
                font-family: "DM Sans";
                font-weight: 400;
                font-size: 1.6rem;
                flex: 1;
                width: 100%;
                line-height: 2.1rem;
                background-image: url("../../assets//icons//link.svg");
                background-repeat: no-repeat;
                background-position: 1.645rem 50%;
                color: #111111;
                &::placeholder {
                    color: #b4b4b4;
                }
            }
            button {
                outline: none;
                border: none;
                background: #000000;
                border-radius: 0px 4px 4px 0px;
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2.1rem;
                color: #ffffff;
                padding: 1.7rem 4rem;
                margin-left: -3%;
            }
        }
    }
    main {
        background: #f8f8f8;
        border-radius: 0 0 24px 24px;
        section {
            margin: 4rem 3.2rem;
            padding: 3rem 2.5rem 5rem 2.5rem;
            border-radius: 2.4rem;
            background-color: white;
        }
    }
}
