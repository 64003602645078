.courseEnrollCard {
    background: #ffffff;
    border: 1px solid #818a9e;
    border-radius: 10px;
    width: 300px;
    position: relative;
    height: 100%;

    &__favorite {
        position: absolute;
        top: 15px;
        z-index: 1000;
        right: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 19px;
            height: auto;
        }
    }
    &__head {
        height: 230px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-text {
            font-family: "Raleway";
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
            text-align: center;
        }
        gap: 2rem;
        img {
            width: 12.5rem;
        }
    }
    &__body {
        background: #0b0f10;
        border-radius: 0px 0px 10px 10px;
        padding: 10px 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        span {
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            text-transform: capitalize;
            color: #ffffff;
        }
        div {
            display: flex;
            gap: 8px;
            align-items: center;
            button {
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                white-space: nowrap;
                text-align: center;
                text-transform: capitalize;
                border: 1px solid #5c53ec;
                color: #5c53ec;
                padding: 5px 17px;
                border-radius: 15px;

                width: fit-content;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: white;
                    background: #5c53ec;
                }
            }
            a {
                text-decoration: none;
                color: inherit;
                button {
                    color: white;
                    background: #5c53ec;
                    &:hover {
                        color: #5c53ec;
                        background: white;
                    }
                }
            }
        }
    }
}

.courseEnrollCard.big {
    height: auto;
}
