.edit-back-button {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 3rem;
    margin-left: 3rem;
    font-size: 1.8rem;
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: underline;
    }
}

.edit-profile-route {
    font-family: "Raleway", sans-serif;
    padding: 3rem 5rem;

    span {
        text-align: end;
        width: 100%;
        font-size: 1.7rem;
        color: #5c53ec;
        text-decoration: underline;
        cursor: pointer;
        &.delete {
            color: rgb(243, 83, 83);
        }
    }

    .modal {
        width: 72rem;
        p {
            font-size: 1.6rem;
            font-weight: 600;
            color: #198754;
        }
    }
    h2 {
        margin-bottom: 2.4rem;
        font-weight: 700;
        font-size: 3rem;
        color: #000000;
        text-transform: capitalize;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
    }
    .name-div {
        display: flex;
        gap: 1.8rem;
    }
    .input-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    label {
        color: #5c53ec;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 120%;
        margin-bottom: 0.8rem;
    }
    input {
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.1rem;
        padding: 0.6rem 1.2rem;
        color: #111;
        border-radius: 0.4rem;
        border: 1px solid rgba(118, 120, 128, 0.5);
        outline: none;
        height: 5rem;
        transition: all 0.3s ease-in-out;
        &::placeholder {
            color: #4f4f4f;
        }
    }
    input:active,
    input:focus {
        border: 1px solid rgb(121, 25, 204);
    }
    button {
        padding-block: 1.1rem;
        background: #000000;
        margin-top: 1rem;
        cursor: pointer;
        outline: none;
        color: white;
        border: none;
        border: 2px solid #111;

        flex: 0;
        width: 20%;
        font-weight: 800;
        transition: all 0.2s ease-in-out;
        font-family: inherit;
        font-size: 1.7rem;
        &:hover,
        &:active {
            color: #000000;
            background-color: transparent;
        }
    }
}
@media screen and (max-width: 775px) {
    .edit-profile-route {
        .modal {
            width: 100%;
        }
        .name-div {
            flex-direction: column;
            gap: 1.8rem;
        }
        button {
            width: 14.4rem;
        }
    }
}
