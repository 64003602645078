.learnList {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 420px;
    position: relative;

    menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 39px;
        height: 39px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 50%;
        gap: 0.2rem;
        div {
            background: #bdbdbd;
            width: 5px;
            height: 5px;
            border-radius: 50%;
        }
        section {
            background: #ffffff;
            border: 1px solid #e6e6e6;
            box-shadow: 1px 0px 10px rgba(173, 184, 216, 0.2);
            border-radius: 4px;
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #272727;
            width: 130px;
            padding: 1rem 1.2rem;
            height: 44px;
            position: absolute;
            top: calc(100% + 7px);
            right: 0px;
        }
    }
    &__name {
        height: 318px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px;
        gap: 3rem;
        text-align: center;
        background-color: white;
        border: 1px solid #818a9e;
        border-radius: 10px 10px 0px 0px;
        font-size: 18px;
        img {
            width: 35%;
        }
        div {
            font-size: 2.4rem;
            color: #6257ff;
            text-transform: capitalize;
        }
    }
    &__course {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        background: #0b0f10;
        border-radius: 0px 0px 10px 9px;
        text-align: center;
        padding: 1.3rem 3rem;
        gap: 15px;
        cursor: pointer;
        &-title {
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            text-align: center;
            text-transform: capitalize;
            color: #ffffff;
        }
    }
    &__button-box {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        button {
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            text-transform: capitalize;
            color: #ffffff;
            background-color: #5c53ec;
            border: 1px solid #5c53ec;
            border-radius: 15000px;
            padding: 0.7rem 2rem;
            white-space: nowrap;
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:last-of-type {
                color: #5c53ec;
                background-color: white;
                &:hover {
                    color: white;
                    background-color: #5c53ec;
                }
            }
            &:hover {
                color: #5c53ec;
                background-color: white;
            }
        }
    }
}
