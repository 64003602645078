.jumbotron {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 22rem;
    background-image: url("../../assets/img/home-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 4rem;
}
.jumbotron__title {
    font-family: "Syne", sans-serif;
    font-weight: 600;
    font-size: 8.2rem;
    line-height: 10rem;
    text-align: center;
    color: var(--primary-color);
    position: relative;
    white-space: normal;
}
.jumbotron__title img {
    position: absolute;
}
.jumbotron__title img:first-of-type {
    top: 0;
    width: 5rem;
    right: -14.2rem;
}
.jumbotron__title img:last-of-type {
    bottom: 0;
    width: 5rem;
    left: -14.2rem;
}
.jumbotron__slogan {
    font-weight: 400;
    font-size: 3.2rem;
    line-height: 3.8rem;
    margin-top: 5.3rem;
    color: #111;
    text-align: center;
}
.jumbotron__button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1.7rem;
    margin-top: 6rem;
}
.jumbotron__button-group button {
    outline: none;
    border: none;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.1rem;
    font-family: "DM Sans", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.7rem;
    cursor: pointer;
    width: 32rem;
    padding: 2rem 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-height: 7rem;
    background: #000;
    border: 1px solid #111;
    border-radius: 2.2rem;
    color: white;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.jumbotron__button-group button:hover {
    background-color: transparent;
    color: #000;
}
.jumbotron__button-group button img {
    width: 3rem;
}
.jumbotron__button-group button svg {
    width: 3rem;
    height: 3rem;
}
.jumbotron__button-group button:last-of-type {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.8rem;
    text-align: center;
    padding-left: 2.3rem;
    color: #5c53ec;
    background-color: white;
    font-family: "Raleway", sans-serif;
}
.jumbotron__button-group button:last-of-type:hover {
    background-color: #5c53ec;
    color: white;
}
.jumbotron__subtitle {
    font-family: "Syne";
    font-weight: 600;
    font-size: 4.3rem;
    line-height: 5.2rem;
    text-align: center;
    color: #1a1a1a;
    width: 62.6rem;
    margin-top: 9rem;
}
.jumbotron__descrip {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 3.2rem;
    line-height: 3.8rem;
    text-align: center;
    color: #fff;
    width: 82.4rem;
    text-align: center;
    color: white;
    background: #5c53ec;
    border-radius: 2.2rem;
    padding: 2.2rem 4.6rem;
    margin-top: 8.5rem;
}
.jumbotron__benefits {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 5.7rem;
    width: 85%;
    max-width: 160rem;
}
.jumbotron__benefits img {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 33%;
}
.jumbotron__benefits svg {
    width: 4rem;
    min-width: 4rem;
}
.jumbotron__benefits .break {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    height: 0;
    display: none;
}
.jumbotron__btn {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 8px;
    font-family: "DM Sans", sans-serif;
    width: 24.2rem;
    padding: 1.5rem 0;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    outline: none;
    margin-top: 5.4rem;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
}
.jumbotron__btn:hover {
    background-color: var(--primary-color);
    color: white;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1440px) {
    .jumbotron__main-container {
        position: relative;
    }
    .jumbotron__main-coursera,
    .jumbotron__main-skillshare,
    .jumbotron__main-teachforamerica,
    .jumbotron__main-linkedin {
        width: 35rem;
    }
    .jumbotron__main-coursera {
        left: -65%;
        top: -15%;
    }
    .jumbotron__main-skillshare {
        right: -60%;
        top: -15%;
    }
    .jumbotron__main-teachforamerica {
        left: -54%;
        bottom: 5%;
    }
    .jumbotron__main-linkedin {
        right: -54%;
        bottom: 5%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1300px) {
    .jumbotron__main-coursera,
    .jumbotron__main-skillshare,
    .jumbotron__main-teachforamerica,
    .jumbotron__main-linkedin {
        width: 30rem;
    }
    .jumbotron__title {
        font-size: 7.4rem;
        line-height: 10rem;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1300px) {
    .jumbotron__main-coursera {
        left: -45%;
        top: -5%;
    }
    .jumbotron__main-skillshare {
        right: -40%;
        top: -5%;
    }
    .jumbotron__main-teachforamerica {
        left: -35%;
        bottom: 5%;
    }
    .jumbotron__main-linkedin {
        right: -30%;
        bottom: 5%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
    .jumbotron__details-container {
        padding: 0 7rem;
        width: 100%;
    }
    .jumbotron__details-purple-1 {
        position: absolute;
        top: -15%;
        left: 2%;
        -webkit-animation: purple-ball 6s infinite ease-in-out;
        animation: purple-ball 6s infinite ease-in-out;
    }
    .jumbotron__details-purple-2 {
        position: absolute;
        top: -15%;
        right: 2%;
        -webkit-animation: purple-ball 6s infinite ease-in-out;
        animation: purple-ball 6s infinite ease-in-out;
    }
    .jumbotron__details-pink-1 {
        position: absolute;
        top: 65%;
        left: 10%;
        -webkit-animation: pink-ball 4s infinite ease-in-out;
        animation: pink-ball 4s infinite ease-in-out;
    }
    .jumbotron__details-pink-2 {
        position: absolute;
        top: 65%;
        right: 10%;
        -webkit-animation: pink-ball 4s infinite ease-in-out;
        animation: pink-ball 4s infinite ease-in-out;
    }
    .jumbotron__details-plum-1 {
        position: absolute;
        bottom: -20%;
        right: 30%;
    }
    .jumbotron__details-plum-2 {
        position: absolute;
        bottom: -20%;
        left: 30%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1150px) {
    .jumbotron__title {
        font-size: 6.4rem;
        line-height: 9rem;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1050px) {
    .jumbotron__main-coursera {
        left: -17%;
        top: 0%;
    }
    .jumbotron__main-skillshare {
        right: -20%;
        top: 0%;
    }
    .jumbotron__main-teachforamerica {
        left: -15%;
        bottom: 5%;
    }
    .jumbotron__main-linkedin {
        right: -15%;
        bottom: 5%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1000px) {
    .jumbotron__benefits {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .jumbotron__benefits svg {
        width: 2rem;
        min-width: 2rem;
    }
    .jumbotron__benefits .break {
        display: block;
    }
    .jumbotron__benefits img:last-of-type {
        max-width: 50rem;
        margin: 0 auto;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 900px) {
    .jumbotron__title {
        font-size: 6rem;
        line-height: 8rem;
    }
    .jumbotron__button-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 3.2rem;
    }
    // .jumbotron__button-group button {
    //     width: 38rem;
    // }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 865px) {
    .jumbotron__descrip {
        width: calc(100% - 6.4rem);
        margin-inline: auto;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 850px) {
    .jumbotron {
        padding-right: 4rem;
        padding-left: 4rem;
    }
    .jumbotron__title img {
        position: absolute;
    }
    .jumbotron__title img:first-of-type {
        top: -30%;
        right: -8.2%;
    }
    .jumbotron__title img:last-of-type {
        bottom: -50%;
        left: -12.2%;
    }
    .jumbotron__slogan {
        margin-top: 2.4rem;
        font-size: 2.8rem;
    }
    .jumbotron__subtitle {
        width: 100%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 750px) {
    .jumbotron__title {
        font-size: 5.5rem;
        line-height: 8rem;
    }
    .jumbotron__main-coursera {
        left: -10%;
        top: 0%;
    }
    .jumbotron__main-skillshare {
        right: -10%;
        top: 0%;
    }
    .jumbotron__main-teachforamerica {
        left: -5%;
        bottom: 10%;
    }
    .jumbotron__main-linkedin {
        right: -5%;
        bottom: 10%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 670px) {
    .jumbotron__title {
        font-size: 5.4rem;
        line-height: 110%;
    }
    .jumbotron__title img {
        width: 3.6rem;
        height: 3.6rem;
    }
    .jumbotron__title img:first-of-type {
        top: -155%;
        right: -6.2%;
    }
    .jumbotron__title img:last-of-type {
        bottom: -155%;
        left: -6.2%;
    }
    .jumbotron__btn {
        width: 34rem;
    }
    .jumbotron__product-hunt {
        width: 34rem;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 650px) {
    .jumbotron__main-coursera {
        left: 0%;
        top: 5%;
    }
    .jumbotron__main-skillshare {
        right: 0%;
        top: 5%;
    }
    .jumbotron__main-teachforamerica {
        left: 0%;
        bottom: 10%;
    }
    .jumbotron__main-linkedin {
        right: 0%;
        bottom: 10%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 600px) {
    .jumbotron__details-purple-1,
    .jumbotron__details-purple-2 {
        width: 4.2rem;
    }
    .jumbotron__details-pink-1,
    .jumbotron__details-pink-2 {
        width: 3.5rem;
    }
    .jumbotron__details-plum-1,
    .jumbotron__details-plum-2 {
        width: 3.5rem;
    }
    .jumbotron__details-title {
        font-size: 3.2rem;
        line-height: 120%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 585px) {
    .jumbotron__title {
        font-size: 4rem !important;
    }
    .jumbotron__slogan {
        margin-top: 1.2rem !important;
        font-size: 2.6rem !important;
        line-height: 1.3;
        width: 70%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 550px) {
    .jumbotron__details-container {
        padding: 0;
    }
    .jumbotron__details-button {
        width: 100%;
    }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 470px) {
    .jumbotron {
        padding-top: 18rem;
    }
    .jumbotron__title {
        font-size: 4.6rem;
    }
    .jumbotron__title img {
        position: absolute;
    }
    .jumbotron__title img:first-of-type {
        // top: -110%;
    }
    .jumbotron__title img:last-of-type {
        // bottom: -110%;
    }
    .jumbotron__slogan {
        font-size: 2.2rem;
        margin-top: 0.6rem;
    }
    .jumbotron__descrip {
        font-size: 2.4rem;
        padding-inline: 8px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 397px) {
    .jumbotron__title {
        font-size: 3.6rem !important;
        img {
            &:first-of-type {
                top: -140%;
                right: -5.2%;
            }
            &:last-of-type {
                bottom: -155%;
                left: -4.2%;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 382px) {
    .jumbotron__slogan {
        font-size: 2.2rem !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 366px) {
    .jumbotron__title {
        font-size: 3.2rem !important;
    }
}
