.avatarModal {
    width: 98rem;
    padding: 7.5rem 8rem;
    border-radius: 2.4rem;
    background: linear-gradient(193.58deg, #fbf2ef 0.84%, #f4f5fd 29.52%, #f4f5fd 68.44%, #fbf2ef 99.17%);
    position: relative;
    .close {
        position: absolute;
        top: 4.2rem;
        right: 4.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
        margin: 0 auto;
        p {
            font-size: 2.2rem;
            font-weight: 500;
            color: #5c53ec;
        }
    }
}

.iFrame {
    border: none;
    width: 100%;
    height: 500px;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    align-self: center;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #5c53ec;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
