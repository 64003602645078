.editPreferences {
    width: 98rem;
    padding: 7.5rem 8rem;
    border-radius: 2.4rem;
    background: linear-gradient(193.58deg, #fbf2ef 0.84%, #f4f5fd 29.52%, #f4f5fd 68.44%, #fbf2ef 99.17%);
    position: relative;
    max-height: calc(100vh - 80px);
    overflow: auto;
    .close {
        position: absolute;
        top: 4.2rem;
        right: 4.2rem;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
            filter: brightness(90%);
        }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    &__title {
        font-family: "Syne";
        font-weight: 600;
        font-size: 3.6rem;
        line-height: 122%;
        color: #111111;
    }
    &__btn {
        outline: none;
        border: none;
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.1rem;
        text-align: center;
        color: #ffffff;
        padding: 1.5rem 6.5rem;
        background: #5c53ec;
        border-radius: 50px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
            filter: brightness(90%);
        }
    }
    &__container {
        width: 100%;
        background: linear-gradient(144.53deg, #ffffff 2.24%, rgba(255, 255, 255, 0) 99.05%);
        box-shadow: 0px 0px 21px 7px rgba(215, 215, 215, 0.16);
        border-radius: 24px;
        padding: 6rem 3rem;
        gap: 3rem;
        display: flex;
        flex-direction: column;
        form {
            padding: 0 1.2rem;
            align-items: center;
            flex-wrap: wrap;
            gap: 4rem;
            row-gap: 2rem;
            display: none;
            &.active {
                display: flex;
            }
            label {
                display: flex;
                align-items: center;
                gap: 0.7rem;
                font-family: "Inter";
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 1.8rem;
                letter-spacing: 0.01em;
                text-transform: capitalize;
                color: #000000;
                cursor: pointer;
            }
            input {
                cursor: pointer;
                width: 2rem;
                height: 2rem;
                border: 1px solid #e7e8e9;
                border-radius: 0.4rem;
            }
        }
    }
    &__box {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        &-title {
            font-family: "Inter";
            font-weight: 600;
            font-size: 1.6rem;
            cursor: pointer;

            line-height: 1.9rem;
            text-transform: capitalize;
            color: #000000;
            padding: 0 1.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(215, 215, 215, 0.5);
            svg {
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                width: 1.5rem;
                height: auto;
                &.active {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 950px) {
    .editPreferences {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
}
